import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';

const publicURL = publicURLTrailingSlash(); // Add this for XMC Pages.

export const loyaltyApi = {
  acceptLoyaltyProgramAgreementEndpoint: () =>
    publicURL + 'api/loyalty/accept-loyalty-program-agreement',
};

export const loginAPI = {
  login: publicURL + 'api/authentication/login',
  lockeduserrequest: publicURL + 'api/authentication/lockeduserrequest',
  forgotPasswordAPI: publicURL + 'api/authentication/forgotPassword',
  unlockuser: publicURL + 'api/authentication/unlockuser',
  resetpassword: publicURL + 'api/authentication/resetpassword',
  validateresetpasswordlink: publicURL + 'api/authentication/validateresetpasswordlink',
  userActivate: publicURL + 'api/authentication/useractivate',
};

export const authenticationAPI = {
  existUser: '/api/authentication/userexist',
  userregister: '/api/authentication/userregister',
};

export const autoshipAPI = {
  cancelAutoship: publicURL + 'api/autoship/cancel-autoship',
  skipAutoship: publicURL + '/api/autoship/skip-autoship',
};

export const yelpAPI = {
  yelp: publicURL + 'api/Yelp/yelp',
};

export const checkoutAPI = {
  submitorder: publicURL + 'api/checkout/submitorder',
};

export const petsApi = {
  allPets: publicURL + 'api/pets/all-pets',
  getGender: publicURL + 'api/pets/genders',
  getSpecies: publicURL + 'api/pets/species',
  getBreed: publicURL + 'api/pets/breeds',
  addPet: publicURL + 'api/pets/add-pet',
  getPet: publicURL + 'api/pets/get-pet',
  updatePet: publicURL + 'api/pets/update-pet',
  deletePets: publicURL + 'api/pets/delete-pet',
  addPetImage: publicURL + 'api/pets/add-pet-image',
};

export const groomingApi = {
  addNewBreedAndAssignToPet: publicURL + 'api/grooming/add-new-breed-and-assign-to-pet',
  createUpdatePet: publicURL + 'api/grooming/create-update-pet',
  groomingServices: publicURL + 'api/grooming/grooming-services',
  nextAvailableTimeslots: publicURL + 'api/grooming/next-available-timeslots',
  timeslotsByDateRangeAndGroomer: publicURL + 'api/grooming/timeslots-by-date-range-and-groomer',
  getMoreAvailableTimeSlots: publicURL + 'api/grooming/get-more-available-time-slots',
  scheduleAppointment: publicURL + 'api/grooming/schedule-appointment',
  availableGroomers: publicURL + 'api/grooming/available-groomers',
  appointments: publicURL + 'api/grooming/appointments',
  cancelAppointment: publicURL + 'api/grooming/cancel-appointment',
};

export const orderPromotionApi = {
  addPromotion: publicURL + 'api/promotion/add-promotion',
  promotionSignup: publicURL + 'api/promotion/promotional-signup',
  promotionCoupon: publicURL + 'api/promotion/GetPromotionCoupon',
};

export const productApi = {
  storeSellsProduct: publicURL + 'api/product/store-sells-product',
  getProductAPI: publicURL + `api/product/get-product-details`,
};

export const storeApi = {
  getStoreDetails: publicURL + 'api/store/get-store-details',
  getStoreDetailsForAutoship: publicURL + 'api/store/get-store-details-for-autoship',
  getDefaultStore: publicURL + 'api/store/get-default-store',
};
