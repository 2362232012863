// Tailwind Import
import deliveryFrequencyVariant from 'tailwindVariants/components/deliveryFrequencyTailwindVariant';
import { useCallback, useEffect, useState } from 'react';
import { ComponentProps } from 'lib/component-props';
import { Field, NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import {
  DiscreteLineItem,
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  sites,
} from 'src/helpers/Constants';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import DeliveryAddressTailwindVariant from 'tailwindVariants/components/deliveryAddressTailwindVariant';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import useOcCart from 'src/hooks/useOcCart';
import { BuyerProductWithXp, LineItemWithXp, OrderWithXp } from 'src/redux/xp';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { patchOrder, updateLineItem } from 'src/redux/ocCurrentOrder';
import AuthenticationCheck, {
  useLoginWithReturnUrl,
} from 'components/AuthenticationCheck/AuthenticationCheck';
import { useAutoshipMessage } from 'src/helpers/utility/AutoshipHelper';
import { useRouter } from 'next/router';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { currencyUsdFormatter } from 'lib/utils/formatters';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import Link from 'next/link';
import { OrderPromotion } from 'ordercloud-javascript-sdk';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import ErrorLabel from '../ErrorLabel/ErrorLabel';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

export type DeliveryFrequencyProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.SetupDeliveryFrequency.Fields.DeliveryFrequency;

const DeliveryFrequency = ({ fields }: DeliveryFrequencyProps): JSX.Element => {
  const { base, title, titleWrapper, linkWithUnderline, productWrapper } = deliveryFrequencyVariant(
    {
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    }
  );

  const { fieldWrapper, submitBtn } = DeliveryAddressTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const ocCurrentOrderObject = useOcSelector((state) => state?.ocCurrentOrder);
  const cart = ocCurrentOrderObject?.order;
  const promotions = ocCurrentOrderObject?.orderPromotions;
  const cartPricing = useCartPriceForUI(ocCurrentOrderObject);
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const hasAutoship = productlineitems.some((x) => x.xp?.Autoship);
  const { checkoutContextData, updateCheckoutContextData, goToNextStep } = useCheckoutFormContext();

  //GTM promotion -----
  const pickup: boolean = cart?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const { currentPage, pageItem, position } = getGTMSessionStorage();
  // const tipLineItem = getTipLineItem() as LineItemWithXp;
  // const totalCount = !pickup
  //   ? tipLineItem?.xp?.TipAmount &&
  //     cart?.Subtotal &&
  //     Number((cart?.Subtotal - tipLineItem?.xp?.TipAmount)?.toFixed(2))
  //   : Number(cart?.Subtotal?.toFixed(2));

  //sending GTM data to data layer

  const productLineItems = ocCurrentOrderObject?.lineItems?.filter(
    (x) => ![DiscreteLineItem.TIP].includes(x.ProductID)
  );

  const sendBeginCheckoutEvent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (pItems: any) => {
      const productData = productLineItems?.map((lineItem) => {
        const products = {
          ...lineItem?.Product,
          quantity: lineItem?.Quantity,
          BasePrice: lineItem?.UnitPrice,
          listPrice: lineItem?.UnitPrice,
        };
        return products;
      });

      !pickup &&
        sendProductsPromotion({
          eventName: GTM_EVENT?.beginCheckout,
          data: (productData ?? pItems) as unknown as ProductSearchResultModelWithVariants[],
          currentPage: currentPage,
          pageItem: pageItem,
          position: position ?? 0,
          storeId: store?.storeId,
          currency: true,
          ShippingTier: !pickup ? GTMLabels?.firstTime : GTMLabels?.pickup,
          totalCount: Number(cartPricing?.subTotal),
          fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
        });
    },
    [pickup, cartPricing]
  );

  const productData = productlineitems?.map((lineItem) => {
    const products = {
      ...lineItem?.Product,
      quantity: lineItem?.Quantity,
      BasePrice: lineItem?.UnitPrice,
      listPrice: lineItem?.UnitPrice,
    };
    return products;
  });

  useEffect(() => {
    const isGTMLoad =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
    const checkGtmLoad = () => {
      if (cart?.xp?.Fulfillment !== FulfillmentType.BOPIS && isGTMLoad && productData) {
        sendBeginCheckoutEvent(productData);
      } else {
        setTimeout(() => {
          checkGtmLoad(), 1000;
        });
      }
    };

    checkGtmLoad();
  }, [cart?.xp?.Fulfillment]);

  //----------

  if (
    !store ||
    Object.keys(store).length === 0 ||
    !cart ||
    cart.xp?.Fulfillment === FulfillmentType.BOPIS
  ) {
    return (
      <div className="deliveryfrequency" hidden>
        {cart?.xp?.Fulfillment}
      </div>
    );
  }

  return (
    <>
      {checkoutContextData?.CheckoutError == true && <ErrorLabel />}
      <div className={clsx('DeliveryFrequency ', base())} id="deliveryfrequency">
        <div className={titleWrapper()}>
          <div className={title()}>1. {fields?.title?.value}</div>
          {/* <div className={linkWithUnderline()}>{fields?.link.}</div> */}
          <LinkHelper field={fields?.link} className={linkWithUnderline()}></LinkHelper>
        </div>
        <div className={productWrapper()}>
          {productlineitems?.map((item) => (
            <DeliveryFrequencyLineItem
              key={item.ID}
              lineItem={item}
              promotions={promotions ?? []}
              fields={fields}
            />
          ))}
        </div>

        <AutoshipFrequency
          autoshipFrequencyNote={fields?.setupAutoshipFrequencyNote}
          autoshipSelectTitle={fields?.setupAutoshipFrequencyTitle}
          autoshipSelectDescription={fields?.setupAutoshipFrequencyDescription}
        />
        {/* Submit Button */}
        <div className={fieldWrapper()}>
          {fields?.continue?.value && (
            <button
              aria-label="submit"
              className={submitBtn({
                className: 'lg:w-[240px] mt-mob-margin-base-top lg:mt-desk-margin-base-top',
              })}
              type="submit"
              onClick={() => {
                if (
                  hasAutoship &&
                  cartPricing?.autoshipSubTotal &&
                  store?.deliveryFee?.minimumAmount > cartPricing?.autoshipSubTotal
                ) {
                  updateCheckoutContextData({ showAutoshipWarning: true });
                } else {
                  goToNextStep('frequency');
                }
              }}
            >
              {fields?.continue?.value || 'Continue'}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default DeliveryFrequency;

function AutoshipFrequency({
  autoshipSelectTitle,
  autoshipSelectDescription,
  autoshipFrequencyNote,
}: {
  autoshipSelectTitle?: Field<string>;
  autoshipSelectDescription?: Field<string>;
  autoshipFrequencyNote?: Field<string>;
}) {
  const {
    autoshipSelect,
    AutoshipFrequencyWrapper,
    selectDropdown,
    autoshipSelectTitleWrapper,
    autoshipSelectDescriptionWrapper,
  } = deliveryFrequencyVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const store = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);

  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();

  const dispatch = useOcDispatch();

  const hasAutoship = productlineitems.some((x) => x.xp?.Autoship);
  const hasProductFirstTimeAutoship = productlineitems.some(
    (x) => x?.Product?.xp?.Autoship && x?.Product?.xp?.FirstTimeAutoshipDiscount
  );

  const defaultAutoshipFrequency = cart?.xp?.AutoshipFrequency
    ? cart?.xp?.AutoshipFrequency
    : store.autoshipSettings?.subscriptionFrequency.find((x) => x.isDefault)?.days ?? 14;

  const [autoshipFrequency, setAutoshipFrequency] = useState(defaultAutoshipFrequency);

  const onSetAutoshipFrequency = useCallback(doSetAutoshipFrequency, [cart, dispatch]);

  async function doSetAutoshipFrequency(newValue: number) {
    if (!cart || cart.xp?.AutoshipFrequency === newValue) {
      return;
    }
    const request: OrderWithXp = { xp: { AutoshipFrequency: newValue } };

    const result = await dispatch(patchOrder(request));
    if (result && typeof result === 'object' && 'error' in result) {
      console.error(result.error);
    } else {
      if (newValue > 0) {
        setAutoshipFrequency(newValue);
      }
    }
  }

  useEffect(() => {
    if (hasAutoship && cart?.xp?.AutoshipFrequency === 0) {
      onSetAutoshipFrequency(autoshipFrequency);
    } else if (!hasAutoship && cart?.xp?.AutoshipFrequency !== 0) {
      onSetAutoshipFrequency(0);
    }
  }, [cart?.xp?.AutoshipFrequency, autoshipFrequency, hasAutoship, onSetAutoshipFrequency]);
  const receivedFirstTimeAutoshipDiscount = useOcSelector(
    (state) => state.ocUser.user?.xp?.ReceivedFirstTimeAutoshipDiscount
  );
  const renderAutoshipFrequencyNote = () => {
    if (!receivedFirstTimeAutoshipDiscount && hasProductFirstTimeAutoship) {
      return (
        <RichTextHelper
          tag="p"
          field={autoshipFrequencyNote}
          updatedField={{
            value:
              autoshipFrequencyNote?.value?.replace(
                '{z}',
                store.autoshipSettings?.firstTimeDiscountLimit?.toString() ?? '25'
              ) || '',
          }}
          className={autoshipSelectDescriptionWrapper()}
        />
      );
    } else {
      return null;
    }
  };
  if (!hasAutoship) {
    return <>{renderAutoshipFrequencyNote()}</>;
  }
  return (
    <div className={AutoshipFrequencyWrapper()}>
      <TextHelper tag="p" field={autoshipSelectTitle} className={autoshipSelectTitleWrapper()} />
      <TextHelper
        tag="p"
        className={autoshipSelectDescriptionWrapper()}
        field={autoshipSelectDescription}
      />
      <div className="relative w-[280px] max-w-[280px]">
        <select
          aria-label="autoship frequency"
          value={autoshipFrequency}
          onChange={(e) => {
            onSetAutoshipFrequency(parseInt(e.target?.value));
          }}
          className={autoshipSelect()}
        >
          {store.autoshipSettings?.subscriptionFrequency.map((freq) => (
            <option key={freq.days} value={freq.days}>
              {freq.label}
            </option>
          ))}
        </select>
        <IconHelper className={selectDropdown()} icon="dropdown" />
      </div>
      {renderAutoshipFrequencyNote()}
    </div>
  );
}

interface DeliveryFrequencyLineItemProps {
  lineItem: LineItemWithXp;
  promotions: OrderPromotion[];
  fields: DeliveryFrequencyProps['fields'];
}

function DeliveryFrequencyLineItem({
  lineItem,
  promotions,
  fields,
}: DeliveryFrequencyLineItemProps) {
  const {
    itemWrapper,
    cardInfoWrapper,
    cardTitleQtyContainer,
    cartTitle,
    cartTitleRed,
    cartTitleRedPill,
    cartTitlePurple,
    hideInMobile,
    centerInMobile,
    radioButtonsWrapper,
    radioField,
    // informationTitle,
    radioNote,
    borderStyles,
    lineItemsWrapper,
    startInMobile,
    priceWrapper,
    singleRadioWrapper,
    autoshipLink,
  } = deliveryFrequencyVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const dispatch = useOcDispatch();

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);

  const productHasAutoship = lineItem.Product?.xp?.Autoship === true;
  const [autoship, setAutoship] = useState(
    !isAnonymous && productHasAutoship && lineItem.xp?.Autoship === true
  );
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );

  const onSetAutoship = useCallback(doSetAutoship, [dispatch, isAnonymous, lineItem]);
  const router = useRouter();
  const context = useSitecoreContext();

  async function doSetAutoship(newValue: boolean) {
    if (isAnonymous) {
      setAutoship(newValue);
      return;
    }
    const updatedLineItem: LineItemWithXp = {
      ...lineItem,
      xp: { ...lineItem.xp, Autoship: newValue },
    };

    const result = await dispatch(updateLineItem(updatedLineItem));
    if (
      result &&
      typeof result === 'object' &&
      'error' in result &&
      !result?.error?.message?.includes('Promotion.')
    ) {
      console.error(result.error);
    } else {
      setAutoship(newValue);
    }
  }

  useEffect(() => {
    if (autoship && (isAnonymous || !productHasAutoship)) {
      onSetAutoship(false);
    }
  }, [autoship, isAnonymous, productHasAutoship, onSetAutoship]);

  const loginWithReturnUrl = useLoginWithReturnUrl();

  const autoshipMessage = useAutoshipMessage({
    product: lineItem.Product,
    autoshipMarketingText: 'AutoshipMarketingCheckout',
  });

  const priceForCheckout = useLineItemPriceForUI(lineItem, promotions);

  const siteName = context.sitecoreContext?.site?.name?.toUpperCase() ?? 'unknown';
  const isPSP = siteName == sites?.psp;

  return (
    <div className={lineItemsWrapper()}>
      {autoship ? <AuthenticationCheck /> : null}
      <div className={clsx(startInMobile(), itemWrapper())}>
        <Link
          aria-label="product-link"
          prefetch={false}
          href={getProductDetailPath(lineItem?.Product as BuyerProductWithXp)}
        >
          <NextImage
            field={{
              value: {
                alt: lineItem?.Product?.Name ?? dummyProductImage?.jsonValue?.value?.alt,
                src:
                  lineItem?.Product?.xp?.Images && lineItem?.Product?.xp?.Images?.length > 0
                    ? getFirstProductImage(lineItem?.Product?.xp?.Images)?.Url
                    : dummyProductImage?.jsonValue?.value?.src,
              },
            }}
            className="w-[104px] max-w-[104px] max-h-[104px] h-auto object-contain"
            width="104"
            height="104"
          />
        </Link>
        <div className={clsx(centerInMobile(), cardInfoWrapper())}>
          <div className={cardTitleQtyContainer()}>
            <div className={cartTitle()}>{lineItem?.Product?.Name}</div>
            <div className={cartTitle({ className: autoship && !isPSP ? 'pr-6' : 'pr-0' })}>
              {fields?.quantity?.value}: {lineItem?.Quantity}
            </div>
          </div>
          <div className={priceWrapper()}>
            <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
              <div className={clsx(hideInMobile(), cartTitle())}>{fields?.listTotal?.value}</div>
              <div className={cartTitle()}>
                {currencyUsdFormatter.format(priceForCheckout.lineItemListPrice ?? 0)}
              </div>
            </div>
            {priceForCheckout.lineItemMemberPrice ? (
              <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative">
                <div className={clsx(hideInMobile(), cartTitle())}>
                  {fields?.memberTotal?.value}{' '}
                </div>
                <div className={clsx(cartTitle(), autoship ? cartTitleRed() : cartTitleRedPill())}>
                  {currencyUsdFormatter.format(priceForCheckout.lineItemMemberPrice)}
                </div>
              </div>
            ) : null}
            {autoship &&
            priceForCheckout?.lineItemAutoshipPrice &&
            priceForCheckout?.lineItemAutoshipPrice > 0 ? (
              <>
                <div className="flex flex-col gap-0 items-start justify-start shrink-0 relative  ">
                  <div className={clsx(hideInMobile(), cartTitle())}>
                    {fields?.autoshipTotal?.value}
                  </div>
                  {/* TODO update autoship total */}
                  <div className={clsx(cartTitle(), cartTitlePurple())}>
                    {currencyUsdFormatter.format(priceForCheckout.lineItemAutoshipPrice ?? 0)}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className={clsx(radioButtonsWrapper(), borderStyles())}>
        {/* currently removed for autoship or delivery case */}
        {/* <div className={informationTitle()}>{fields?.pickupTitle?.value}</div> */}
        <div role="group" aria-labelledby="my-radio-group" className={radioButtonsWrapper()}>
          <label
            className={clsx(singleRadioWrapper(), 'pb-[12px]')}
            htmlFor={`onetime-${lineItem.ID}`}
          >
            <input
              aria-label="delivery-method-dfs"
              className={clsx(radioField(), 'mr-[4px]')}
              id={`onetime-${lineItem.ID}`}
              type="radio"
              value="onetime"
              checked={!autoship}
              onChange={() => onSetAutoship(false)}
            />
            {fields?.oneTimeDelivery?.value}
          </label>
          {lineItem.Product?.xp?.Autoship ? (
            <label
              className={clsx(singleRadioWrapper(), 'pb-[12px]', {
                '!text-color-scale-7-mid-dark': isAnonymous,
              })}
              htmlFor={`autoship-${lineItem.ID}`}
            >
              <input
                aria-label="delivery-method-autoship"
                className={clsx(radioField(), 'mr-[4px]')}
                id={`autoship-${lineItem.ID}`}
                type="radio"
                value="autoship"
                checked={autoship}
                onChange={() => {
                  if (isAnonymous) {
                    router.push(loginWithReturnUrl);
                  } else {
                    onSetAutoship(true);
                  }
                }}
              />
              {fields?.autoshipWithDelivery?.value}
            </label>
          ) : null}

          {isAnonymous && lineItem.Product?.xp?.Autoship ? (
            <LinkHelper
              field={{
                value: {
                  href: loginWithReturnUrl,
                  text: fields?.autoshipLoginMessage?.value,
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                router.push(loginWithReturnUrl);
              }}
              className={autoshipLink()}
            ></LinkHelper>
          ) : null}

          {autoshipMessage ? (
            <span className={radioNote({ className: isAnonymous ? ' !ml-1' : '' })}>
              {isAnonymous ? '- ' : ''}
              {autoshipMessage}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
