//global
import {
  ComponentParams,
  ComponentRendering,
  GetStaticComponentProps,
  Placeholder,
  Field,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecoreTemplateId } from 'lib/constants';
import { getAppRoot } from 'lib/edge-runtime/graphql/get-app-root';
import React, { useEffect, useState } from 'react';
import { findMatchingItem, getMatchData } from 'lib/utils/string-utils';
// import PreviewSearch from 'src/helpers/search/PreviewSearch';

import SearchResultsWidget from 'src/helpers/search/SearchResults';
import { RerenderOnRouteChange } from 'src/helpers/utility/RerenderOnRouteChange';
import { useSearchParams } from 'next/navigation';
import { rfkidOf } from 'src/helpers/Constants';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { MarketingTileData } from 'src/helpers/search/MarketingTile';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { useOcSelector } from 'src/redux/ocStore';

interface ProductListingProps {
  facetSettings: FacetSettingMap;
  visualFilterData: FacetFilterMap;
  params: ComponentParams;
  templateId: string;
  marketingTileData?: MarketingTileData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  marketingTileSettings?: any;
  rendering: ComponentRendering & {
    placeholders: {
      [key: string]: {
        fields: MarketingTileData;
      }[];
    };
  };
  shopFilter?: string;
  shopFilterValue?: string;
}
const ProductListing: React.FC = (props: ProductListingProps) => {
  const isExperienceEditor = useExperienceEditor();
  const { sitecoreContext } = useSitecoreContext();
  const templateId = sitecoreContext?.route?.templateId;

  //getting shopsValue and Filters from Sitecore Context
  const shopFilter = (sitecoreContext?.route?.fields?.shopFilterField as Field<string>)?.value;
  const shopFilterValue = (sitecoreContext?.route?.fields?.shopFilterValue as Field<string>)?.value;

  return (
    <div>
      {isExperienceEditor && <Placeholder name="plp-marketing-tile" rendering={props.rendering} />}
      <RerenderOnRouteChange>
        <SearchWidgetWrapper
          {...props}
          templateId={templateId || ''}
          marketingTileSettings={props?.marketingTileSettings}
          marketingTileData={
            props?.rendering?.placeholders?.['plp-marketing-tile']?.[0]?.fields as MarketingTileData
          }
          shopFilter={shopFilter}
          shopFilterValue={shopFilterValue}
        />
      </RerenderOnRouteChange>
    </div>
  );
};

// Pulling this out to a sub component so the logic can live inside of `<RerenderOnRouteChange>` instead of outside.
// Since these parameters have a dependency on route
function SearchWidgetWrapper({
  params,
  facetSettings,
  visualFilterData,
  marketingTileData,
  marketingTileSettings,
  shopFilter,
  shopFilterValue,
  templateId,
}: ProductListingProps) {
  const searchParams = useSearchParams();
  const query = searchParams.get('q') || searchParams.get('query') || '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newMarketingTileData, setNewMarketingTileData] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);

  useEffect(() => {
    const findMatch = async () => {
      const matchedItem = await findMatchingItem(
        query,
        marketingTileSettings?.children?.results || [],
        getMatchData
      );

      if (matchedItem) {
        setNewMarketingTileData(matchedItem?.tileDatasource?.targetItem);
      }
    };
    if (query) {
      findMatch();
    }
  }, [query, myStoreData?.storeId]);

  return (
    <SearchResultsWidget
      key={myStoreData?.storeId}
      rfkId={params?.searchResultsRFKId ? params?.searchResultsRFKId : rfkidOf?.plpResults}
      facetSettings={facetSettings}
      defaultKeyphrase={query}
      visualFiltersData={visualFilterData}
      marketingTileData={query ? newMarketingTileData : marketingTileData}
      shopFilter={shopFilter}
      shopFilterValue={shopFilterValue}
      templateId={templateId}
    />
  );
}

export default ProductListing;

const getSiteWisePathNameForQuery = (siteName: string) => {
  return siteName?.toUpperCase() === 'PSP'
    ? '{60EB345F-EEFA-4476-99C1-D37ED2180FE6}'
    : '{253D1CC2-9C2C-4E4A-91D7-986822C9A0D0}';
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphqlClientFactory({});
  console.log('@@_rendering', rendering);
  const siteName = layoutData.sitecore.context.site?.name ?? 'psp';
  if (!siteName) {
    console.error('Site name not found');
    return {
      facetSettings: {},
    };
  }
  const language = layoutData.sitecore.context.language ?? 'en';
  if (!language) {
    console.error('language not found');
    return {
      facetSettings: {},
    };
  }
  const appRootId = await getAppRoot(siteName, language);
  if (!appRootId) {
    console.error(`appRootId not found for site: "${siteName}" in language: "${language}"`);
    return {
      facetSettings: {},
    };
  }
  const facetSettings = await getFacetSettings();
  const visualFilterSettings = await getVisualFilterSettings(siteName);
  const marketingTileSettings = (await getMarketingTileSettings(siteName)) || {};

  return {
    facetSettings: facetSettings,
    visualFilterData: visualFilterSettings,
    marketingTileSettings: marketingTileSettings,
  };

  // Function to call GQL visual filter for search query:
  async function getVisualFilterSettings(siteName: string) {
    const visualFilterData = await graphQLClient.request<QueryResponse>(
      visualFilterQuery?.replace('{SITENAME}', getSiteWisePathNameForQuery(siteName)),
      {
        language: layoutData?.sitecore?.context?.language,
        appRootId,
      }
    );
    const visualFilterResponse = visualFilterData?.search?.results[0];
    return visualFilterResponse;
  }

  // Function to call GQL visual filter for search query:
  async function getMarketingTileSettings(siteName: string) {
    const MarketingTileData = await graphQLClient.request<QueryResponse>(
      marketingTileQuery?.replace('{SITENAME}', getSiteWisePathNameForQuery(siteName)),
      {
        language: layoutData?.sitecore?.context?.language,
        appRootId,
      }
    );
    const MarketingTileResponse = MarketingTileData?.search?.results[0];
    return MarketingTileResponse;
  }

  async function getFacetSettings() {
    const data = await graphQLClient.request<QueryResponse>(query, {
      language: layoutData?.sitecore?.context?.language,
      appRootId,
    });

    const facetSettings = data.search.results
      .filter((x) => x.facetName?.value)
      .reduce((prev, curr) => {
        const facet: FacetSetting = {
          facetName: curr.facetName?.value ?? '',
          facetLabelOverride: curr.facetLabelOverride?.value ?? '',
          facetDefaultOpen: curr.facetDefaultOpen?.value === '1',
          facetValueOverrideMap:
            curr.facetValueOverrideMap?.values.reduce((prev, curr) => {
              return { ...prev, [curr.name]: decodeURI(curr?.value) };
            }, {} as Record<string, string>) ?? {},
          mergedFacets: curr.mergedFacets?.value.split('|').filter(Boolean) ?? [],
        };

        prev[facet.facetName] = facet;

        return prev;
      }, {} as FacetSettingMap);
    return facetSettings;
  }
};

export type FacetSettingMap = Record<string, FacetSetting | undefined>;
export type FacetFilterMap = Record<string, FacetFilter | undefined>;

export interface FacetFilter {
  [key: string]: string;
}

export interface FacetSetting {
  facetName: string;
  facetLabelOverride: string;
  facetDefaultOpen: boolean;
  facetValueOverrideMap: Record<string, string>;
  mergedFacets: string[];
}
interface QueryResponse {
  search: {
    results: {
      facetName?: {
        value: string;
      };
      facetLabelOverride?: {
        value: string;
      };
      facetDefaultOpen?: {
        value: '1' | '0' | '';
      };
      facetValueOverrideMap?: {
        values: {
          name: string;
          value: string;
        }[];
      };
      mergedFacets?: {
        value: string;
      };
    }[];
  };
}

// TODO Update this to be site specific
const query = /* GraphQL */ `
  query GetFacets($appRootId: String!, $language: String = "en") {
    search(
      where: {
        AND: [
          # Hard coded query values need to appear before variable ones.
          { name: "_templates", value: "${SitecoreTemplateId.FacetItem}" }
          { name: "_path", value: $appRootId }
          { name: "_language", value: $language }
        ]
      }
      first: 100
    ) {
      results {
        ... on FacetItem {
          facetName {
            value
          }
          facetLabelOverride {
            value
          }
          facetDefaultOpen {
            value
          }
          facetValueOverrideMap {
            values {
              name
              value
            }
          }
          mergedFacets {
            value
          }
        }
      }
    }
  }
`;

// Query for Visual Filters upon seach query:
const visualFilterQuery = `
{
  search(
    where: {
      AND: [
        { name: "_path", value: "{SITENAME}" }
        { name: "_templates", value: "{46FDA11A-AE74-4F69-9BBD-6250CC02C360}" }
      ]
    }
  ) {
    results {
      path
      children(first: 100) {
        results {
          ... on SearchPhraseMapItem {
            exclusionMatch{
              value
            }
            exclusions{
              value
            }
            searchPhrase{
              value
            }
            wordMatch{
              value
            }
            visualFilterDatasource {
              jsonValue
            }
          }
        }
      }
    }
  }
}`;

const marketingTileQuery = `{
  search(
    where: {
      AND: [
        { name: "_path", value: "{SITENAME}" }
        { name: "_templates", value: "{13673CEA-CF12-4259-9006-5606AD57F532}" }
      ]
    }
  ) {
    results {
      path
      children(first: 100) {
        results {
          ... on SearchMarketingTileMapItem {
            exclusionMatch{
              value
            }
            exclusions{
              value
            }
            searchPhrase{
              value
            }
            wordMatch{
              value
            }
            tileDatasource {
              value
              targetItem {
                  columnPosition:  field(name:"columnPosition")
                {
                  value
                }
               columnSpan :  field(name:"columnSpan")
                {
                  value
                }
                 tileImage :  field(name:"tileImage")
                {
                  jsonValue
                }
                  mobileTileImage :  field(name:"mobileTileImage")
                {
                  jsonValue
                }
              rowReplacement:  field(name:"rowReplacement")
                {
                  value
                }
                 text:  field(name:"text")
                {
                  value
                }
                  mobileRowReplacement:  field(name:"mobileRowReplacement")
                {
                  value
                }
                  link:  field(name:"link")
                {
                  jsonValue
                }
                promotionId:  field(name:"promotionId")
                {
                  value
                }
                componentName:  field(name:"componentName")
                {
                  value
                }
                promotionName:  field(name:"promotionName")
                {
                  value
                }
                promotionCopy:  field(name:"promotionCopy")
                {
                  value
                }
                promotionDateFROM:  field(name:"promotionDateFROM")
                {
                  value
                }
                promotionDateTO:  field(name:"promotionDateTO")
                {
                  value
                }
                cTAButtonCopy:  field(name:"cTAButtonCopy")
                {
                  value
                }
                promotionURL:  field(name:"promotionURL")
                {
                  value
                }
                creativeSlotNumber:  field(name:"creativeSlotNumber")
                {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}`;
