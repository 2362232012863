//global
import clsx from 'clsx';
import { Field, LinkField, NextImage, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { Data, SubLink } from 'lib/types/footer';
import { useTheme } from 'lib/context/ThemeContext';
import { useEffect, useState } from 'react';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { footerTailwindVariant } from 'tailwindVariants/components/footerTailwindVariant';
//types
interface FooterNavProps {
  navitems: SubLink;
  data: Data;
  index: number;
  isToggled: number;
  link: { jsonValue: LinkField } | undefined;
  linkTitle: { jsonValue: Field<string> } | undefined | null;
  setisToggled: (val: number) => void;
}

export default function FooterNav({
  navitems,
  data,
  index,
  link,
  linkTitle,
  isToggled,
  setisToggled,
}: FooterNavProps) {
  const { themeName } = useTheme();
  const [isMobile, setisMobile] = useState<number>();
  const len = data?.datasource?.list?.targetItems?.length || 0;
  const {
    columnWrapper,
    titleWrapper,
    headingWrapper,
    subNavWrapper,
    iconWrapper,
    linkWrapper,
    logoWrapper,
    arrowIcon,
    soicialLi,
  } = footerTailwindVariant({ device: { initial: 'mobile', lg: 'desktop' }, theme: themeName });
  const handleToggle = (val: number) => {
    val === isToggled ? setisToggled(-1) : setisToggled(val);
  };
  //handling the accordion animation
  useEffect(() => {
    const handleResize = () => {
      setisMobile(window?.innerWidth);
      if (window?.innerWidth >= 1024) {
        setisToggled(-1);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, setisToggled]);
  useEffect(() => {
    const item = document?.querySelectorAll('.nav');
    item.forEach((item) => {
      const desc = item.querySelector<HTMLElement>('.desc');
      if (item.classList.contains('open')) {
        desc ? (desc.style.height = `${desc?.scrollHeight}px`) : '';
      } else {
        desc ? (desc.style.height = `0px`) : '';
      }
    });
  }, [isMobile, isToggled]);

  return (
    <div
      className={clsx(columnWrapper(), 'nav', `${isToggled === index ? 'open' : ''}`, {
        '!border-b-0 !border-r-0 !mr-0 !pr-0': index === len - 1,
      })}
    >
      <div
        className={clsx(titleWrapper(), { 'hidden lg:flex': index === len - 1 })}
        onClick={() => {
          handleToggle(index);
        }}
      >
        {link?.jsonValue?.value?.href && link?.jsonValue?.value?.href?.length > 0 ? (
          <LinkHelper field={link?.jsonValue} className={headingWrapper()} editable={false} />
        ) : (
          linkTitle?.jsonValue && (
            <TextHelper tag="h4" field={linkTitle?.jsonValue} className={headingWrapper()} />
          )
        )}
        <button aria-label="chevron-down" className={iconWrapper()}>
          <IconHelper icon={'chevron-down'} className={clsx(arrowIcon())} />
        </button>
      </div>
      <ul
        className={clsx(subNavWrapper(), 'desc lg:!h-fit', {
          '!flex !h-fit justify-center mx-auto lg:mx-[0px] w-max gap-desk-space-between-base-horizontal my-2 lg:justify-normal flex-wrap':
            index === len - 1,
        })}
      >
        {navitems?.targetItems?.map((sublink, index) => {
          return sublink?.Image ? (
            <li key={index} className={soicialLi()}>
              {sublink?.TargetUrl && (
                <LinkHelper
                  editable={false}
                  hideLinkInEE
                  field={sublink?.TargetUrl?.jsonValue}
                  className={clsx(linkWrapper(), 'hover:text-color-text-white')}
                >
                  <NextImage field={sublink?.Image?.jsonValue} />
                </LinkHelper>
              )}
            </li>
          ) : (
            <li key={index}>
              {sublink?.link?.jsonValue?.value?.href &&
              sublink?.link?.jsonValue?.value?.href?.length > 0 ? (
                <LinkHelper
                  field={sublink?.link?.jsonValue}
                  className={linkWrapper()}
                  editable={false}
                />
              ) : (
                ''
              )}
            </li>
          );
        })}
      </ul>
      {index === len - 1 ? (
        <div className={'flex flex-col items-center'}>
          <NextImage field={data?.datasource?.footerLogo?.jsonValue} className={logoWrapper()} />
          <RichText
            tag="p"
            field={data?.datasource?.copyRightText}
            className={clsx(linkWrapper(), 'pb-0 hover:text-color-text-white')}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
