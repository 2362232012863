/* eslint-disable @typescript-eslint/no-explicit-any */
// Tailwind Import
import orderSummary from 'tailwindVariants/components/orderSummaryTailwindVariant';
import { ComponentProps } from 'lib/component-props';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
// import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useOcSelector, useOcDispatch } from 'src/redux/ocStore';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { FulfillmentType, currentPath } from 'src/helpers/Constants';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
import { analyseAuthForLoginCheckout } from 'src/helpers/LoginCheckout';
import {
  calculateOrder,
  removePromotion,
  patchOrder,
  reapplyPromotions,
  removeLineItem,
} from 'src/redux/ocCurrentOrder';
import ComponentContext from 'lib/context/ComponentContext';
import router from 'next/router';
import urlConfig from 'src/utils/urlConfig';
import formatPrice from 'src/utils/formatPrice';
import {
  checkoutPreflightChecks,
  isItemValidForCheckout,
} from 'components/Checkout/helper/preflight';
import { PSP } from 'models/PetSuppliesPlus.Model';
import useDictionary from 'src/hooks/useDictionary';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import CouponCode from './CouponCode';
import Loader from 'components/Loader/Loader';
import { Order } from 'ordercloud-javascript-sdk';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import { unwrapResult } from '@reduxjs/toolkit';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { useIsSoftLoginEnabled } from 'src/hooks/useIsSoftLoginEnabled';

export type orderSummary = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.OrderSummary.Fields.OrderSummary;

const OrderSummary = ({ fields }: orderSummary): JSX.Element => {
  // Tailwind Variant
  const {
    base,
    headline,
    block,
    title,
    titleMb,

    summary,
    regular,
    bold,
    summaryBorder,
    summaryMargin,
    summaryMarginLeft,
    wrapIcon,
    icon,
    tooltipContainer,
    topTooltipWrapper,
    topTooltipArrowFirstDiv,
    topToolTipDescription,
    topTooltipArrowSecondDiv,
    linkWithUnderline,
    skeletonLoading,
    loadingImageWrapper,
    blockOpacity50,
    blockOpacity100,
  } = orderSummary({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const empty = <div className="orderSummary" hidden></div>;
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const GlobalStore = useOcSelector((state) => state?.storeReducer?.globalStoreSettings);
  const ocCurrentOrderObject = useOcSelector((state) => state?.ocCurrentOrder);
  const cart = ocCurrentOrderObject.order;
  const ocpromotionData = useOcSelector((state) => state?.ocCurrentOrder?.orderPromotions);
  const taxDetail = useOcSelector(
    (state) => state?.ocCurrentOrder?.orderCalculateResponse?.xp?.TaxDetails
  );
  const calculating = useOcSelector((state) => state?.ocCurrentOrder?.calculating);
  const { getProductLineItems, getTipLineItem } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const tiplineitem = getTipLineItem() as LineItemWithXp;

  const [showTooltip, setShowTooltip] = useState(false);
  const [isPromotionSubmiting, setIsPromotionSubmiting] = useState(false);
  const [isShoppingCartPage, setIsShoppingCartPage] = useState(false);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { checkoutContextData, updateCheckoutContextData } = useCheckoutFormContext();
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);
  const [passesPreflightCheck, setPassesPreflightCheck] = useState<boolean | null>(null);
  const [_disableDelivery, setDisableDelivery] = useState<number>();
  const [hideDeliveryTipping, setHideDeliveryTipping] = useState<number>();

  const [isSticky, setIsSticky] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const originalPositionRef = useRef<number | null>(null);
  const originalWidthRef = useRef<number | null>(null);
  const onloadCalculate = useRef(false);

  const cartPricing = useCartPriceForUI(ocCurrentOrderObject);

  const hasAutoship = productlineitems.some((x) => x.xp?.Autoship);
  const subTotal = cartPricing.subTotal;
  const handleScroll = () => {
    const footer = document.getElementById('newsletter');
    const component = componentRef.current;
    if (!footer || !component) return;
    const isDesktop = window.innerWidth > 1024;

    if (!isDesktop) {
      setIsSticky(false);
      return;
    }

    const footerTop = footer.getBoundingClientRect().top;
    const componentTop = component.offsetTop;
    const scrollTop = window.scrollY || window.pageYOffset;

    const shouldStick = componentTop <= scrollTop && footerTop > window.innerHeight;
    setIsSticky(shouldStick);

    if (originalPositionRef.current === null) {
      originalPositionRef.current = componentTop;
    }

    if (originalWidthRef.current === null) {
      originalWidthRef.current = component.offsetWidth;
    }

    if (scrollTop <= originalPositionRef.current) {
      setIsSticky(false);
    }
  };
  const dispatch = useOcDispatch();
  const { getDictionaryValue } = useDictionary();

  const retailDeliveryFee =
    taxDetail?.LineItems?.find((l) => l.LineItemID == 'RetailDeliveryFee')?.LineItemTotalTax ?? 0;
  const delivery: boolean = cart?.xp?.Fulfillment == FulfillmentType?.DFS;
  const pluPromotion = ocpromotionData?.filter(
    (p) => p.xp != null && p.xp.IsPLU != null && p.xp.IsPLU === true
  );
  const pathName = useRealPathName();
  const { numberOfItemsInCart } = useOcCart();

  const ecommerceDisabled = myStoreData?.ecommerceDisabled;

  const removeLine = async (line: LineItemWithXp) => {
    if (line?.ID) {
      await dispatch(removeLineItem(line.ID)).then(async () => {
        const calculateOrderResponse = await dispatch(calculateOrder());
        setcomponentContextData({
          ...componentContextData,
          unavailableItemsRemoved: true,
        });

        const worksheet = unwrapResult(calculateOrderResponse);
        // Redirect will happen to homepage in case cart got empty because products were removed due to webexcludeded true.
        if (worksheet?.Order?.Total === 0) {
          router.push(currentPath?.isHome);
        }
      });
    }
  };

  const callPreflightCheck = async () => {
    try {
      const cartItems = await numberOfItemsInCart();
      const currentCartSubTotal = subTotal;
      const preflightResponse = await checkoutPreflightChecks(
        cartItems,
        currentCartSubTotal,
        productlineitems
      );
      setPassesPreflightCheck(preflightResponse);
    } catch (e) {
      console.error('Error during preflight check:', e);
    }
  };

  const callItemValidCheck = async () => {
    const invalidItems = await isItemValidForCheckout(productlineitems);
    if (Array.isArray(invalidItems) && invalidItems.length > 0) {
      invalidItems.forEach((item) => {
        removeLine(item);
      });
    }
    // Commenting due to checkout page is breaking because of infinite calls
    // else {
    //   setcomponentContextData({
    //     ...componentContextData,
    //     unavailableItemsRemoved: false,
    //   });
    // }
  };

  useEffect(() => {
    // to avoid user checkout journey is eComm is disabled and user navigates directly through URL.
    if (pathName == currentPath?.isCheckout && ecommerceDisabled === 1) {
      // get redirect url from Global Store Setting.
      if (GlobalStore?.data?.data?.getGlobalStoreSetting?.ecommerceDisabledRedirect) {
        router.push(
          GlobalStore?.data?.data?.getGlobalStoreSetting?.ecommerceDisabledRedirect?.value
            ?.href as string
        );
      } else {
        // keeping this as a fallback
        router.push(currentPath?.isHome);
      }
    }
  }, [ecommerceDisabled]);

  useEffect(() => {
    // if no data is found on store, it will take Global delivery as fallback from the GQL itself.
    setDisableDelivery(Number(myStoreData?.disableDelivery));
    if (
      pathName === currentPath?.isCheckout &&
      Number(myStoreData?.disableDelivery) !== undefined
    ) {
      if (
        Number(myStoreData?.disableDelivery) === 1 &&
        cart?.xp?.Fulfillment == FulfillmentType?.DFS
      ) {
        // For now, sending user to home as we have no confirmation from PSP team.
        router.push(currentPath?.isHome);
      }
    }
  }, [myStoreData?.disableDelivery]);

  useEffect(() => {
    // if no data is found on store, it will take delivery tipping as fallback from the GQL itself.
    setHideDeliveryTipping(Number(myStoreData?.disableTipping));
  }, [myStoreData?.disableTipping]);

  const isSoftLoginEnabled = useIsSoftLoginEnabled();
  useEffect(() => {
    setcomponentContextData({
      ...componentContextData,
      unavailableItemsRemoved: false,
    });
    const shoppingCart = document.getElementsByClassName('cartItemListing');
    setIsShoppingCartPage(shoppingCart.length > 0 ? true : false);

    // if SoftLogin Enabled, redirect to the Login Page:
    if (isSoftLoginEnabled && shoppingCart.length > 0) {
      router.push(currentPath?.isLogin);
    }

    if (myStoreData?.checkoutDisable === 1 && isAnonymous) {
      router.push(currentPath?.isLogin);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (pathName == currentPath?.isCheckout && passesPreflightCheck === false) {
      // turn off warning delivery modal threshold before heading to home page.
      setcomponentContextData({
        ...componentContextData,
        showDeliveryThreashold: false,
      });
      // TODO: Make it Redirect CMS Configurable:
      router.push(currentPath?.isHome);
    }
  }, [cart?.xp?.Fulfillment, passesPreflightCheck]);

  const handleDeliveryThreshold = () => {
    if (
      pathName == currentPath?.isCheckout &&
      cart?.xp?.Fulfillment == FulfillmentType?.DFS &&
      (myStoreData as any)?.deliveryFee?.minimumAmount > subTotal
    ) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryThreashold: true,
      });
    } else {
      if (componentContextData?.showDeliveryThreashold) {
        setcomponentContextData({
          ...componentContextData,
          showDeliveryThreashold: false,
        });
      }
    }
  };
  // PSP-1914
  useEffect(() => {
    handleDeliveryThreshold();
  }, [cart?.xp?.Fulfillment, myStoreData?.deliveryFee?.minimumAmount]);

  /*
  //When Shopping Cart or Checkout page loads, we call calculate
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (cart?.xp?.Fulfillment) {
        dispatch(calculateOrder());
      }
    }
  }, []);
*/

  /**
   * To toggle Payment Authorization failed status if fulfillment is changed.
   */
  const checkPaymentStatus = () => {
    if (pathName == currentPath?.isCheckout && checkoutContextData?.CheckoutError == true) {
      updateCheckoutContextData({ CheckoutError: false });
    }
  };

  useEffect(() => {
    //This useeffect is meant to be used for one time execution needed on page/component load.
    const onload = async () => {
      if (cart?.xp?.Fulfillment && !onloadCalculate.current) {
        onloadCalculate.current = true;
        if (!isAnonymous) {
          await dispatch(reapplyPromotions());
        }
        await dispatch(calculateOrder());
        onloadCalculate.current = false;
      }
    };
    try {
      onload();
      checkPaymentStatus();
    } catch (_e) {
      console.error(_e);
    }
  }, [cart?.xp?.Fulfillment]);

  useEffect(() => {
    if (ocCurrentOrderObject?.initialized && ocCurrentOrderObject?.order) {
      // atleast ocCurrentOrderObject has order object and only when the redux state is initialized in the fulfilled state.
      callPreflightCheck();
      callItemValidCheck();
    }
  }, [ocCurrentOrderObject?.initialized, productlineitems]);

  const handleUserCheck = (redirectLink: string) => {
    const response = analyseAuthForLoginCheckout(
      isAnonymous as boolean,
      redirectLink,
      currentPath?.isShoppingCart,
      cart?.xp?.Fulfillment == FulfillmentType?.DFS,
      (myStoreData as any)?.deliveryFee?.minimumAmount > subTotal
    );
    if (response?.openDeliveryWarningThreashold) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryThreashold: true,
      });
    }

    if (response?.redirectToLoginCheckout) {
      if (isAnonymous) {
        return router.push(`${urlConfig?.loginCheckoutWithReturnUrl}${currentPath?.isCheckout}`);
      }
    }
    if (response?.redirectURL) {
      return router.push(response?.redirectURL);
    }
    return null;
  };

  const handlePromotionRemoveClick = async (promoCode: string | undefined) => {
    setIsPromotionSubmiting(true);
    try {
      if (promoCode) {
        const request: Order = { xp: { CouponCode: '' } };
        await dispatch(patchOrder(request));
        await dispatch(removePromotion(promoCode));
      }
    } catch (e) {
      console.error('An unknown error occurred', e);
    }
    setIsPromotionSubmiting(false);
  };

  if (!myStoreData || !productlineitems?.length) {
    return empty;
  }
  const deliveryFee = cart?.ShippingCost?.toFixed(2);
  return (
    <div
      data-component={'components/OrderSummary/OrderSummary'}
      ref={componentRef}
      className={'orderSummary bg-color-background-white' + base()}
      style={{
        position: isSticky ? 'fixed' : 'static',
        top: isSticky ? 0 : 'auto',
        width: isSticky && originalWidthRef.current ? originalWidthRef.current + 'px' : 'auto',
        zIndex: isSticky ? '11' : '0', // Z-index Set 11 as on Checkout Page Step-1 Pickup Address Form was set to 10.
      }}
    >
      <div>
        <div
          className={clsx(
            block(),
            summaryMargin(),
            'mb-mob-margin-base-bottom lg:mb-desk-margin-base-bottom'
          )}
        >
          <div className="flex flex-col gap-[24px]">
            {fields?.title?.value && <div className={headline()}>{fields?.title?.value}</div>}
            {fields?.couponTitle?.value && (
              <div>
                <div className={clsx(title(), titleMb())}> Have a coupon code?</div>
                {fields?.login && isAnonymous && (
                  <RichTextHelper className={clsx(title(), titleMb())} field={fields?.login} />
                )}

                <CouponCode
                  unrecognizedPlu={fields?.unrecognizedPlu?.value}
                  couponLimitText={fields?.couponLimitText?.value}
                  unrecognizedBarcode={fields?.unrecognizedBarcode?.value}
                  ineligibleCoupon={fields?.ineligibleCoupon?.value}
                  errorPopupTitle={fields?.errorPopupTitle?.value}
                  applyButtonText={fields?.applyButtonText?.value}
                  isAnonymous={isAnonymous}
                  isPromotionSubmiting={isPromotionSubmiting}
                  setIsPromotionSubmiting={setIsPromotionSubmiting}
                />
              </div>
            )}
          </div>
        </div>

        <div className={clsx(block(), calculating ? blockOpacity50() : blockOpacity100())}>
          <div className={clsx(summary(), summaryMargin())}>
            <span className={clsx(regular(), summaryMargin())}>List Price</span>
            {calculating && <span className={skeletonLoading()}></span>}
            {!calculating && (
              <span className={regular()}>${cartPricing.listPriceTotal.toFixed(2)}</span>
            )}
          </div>

          {/* Total Member Savings & loggedIn User */}
          {fields?.totalMemberSavings && !isAnonymous && (
            <div className={clsx(summary(), summaryBorder(), 'gap-[8px] pt-[10px]')}>
              <span className={clsx(bold(), summaryMargin())}>
                {fields?.totalMemberSavings?.value}
              </span>
              {calculating && <span className={skeletonLoading()}></span>}
              {!calculating && (
                <span className={bold()}>${cartPricing.totalMemberSavings.toFixed(2)}</span>
              )}
            </div>
          )}
          {/* total Member savings & guest user*/}
          {fields?.totalMemberSavings && isAnonymous && (
            <div className={clsx(summary(), summaryBorder(), 'gap-[8px] pt-[10px]')}>
              <span className={clsx(bold(), summaryMargin(), 'min-w-[162px]')}>
                {fields?.totalMemberSavings?.value}
              </span>
              {fields?.login && isAnonymous && (
                <RichTextHelper
                  className={clsx(
                    title(),
                    titleMb(),
                    '!font-body-small-bold !text-body-small-bold !leading-body-small-bold !max-w-[169px] text-end'
                  )}
                  field={fields?.login}
                />
              )}
            </div>
          )}

          {fields?.salePricing && !isAnonymous && cartPricing?.salePriceSavings > 0 && (
            <div className={clsx(summary(), summaryMargin())}>
              <span className={clsx(regular(), summaryMarginLeft())}>
                {fields?.salePricing?.value}
              </span>

              {calculating && <span className={skeletonLoading()}></span>}
              {!calculating && (
                <span className={clsx(regular())}>${cartPricing.salePriceSavings.toFixed(2)}</span>
              )}
            </div>
          )}

          {fields?.autoshipDiscount && hasAutoship && cartPricing?.autoshipDiscount > 0 && (
            <div className={clsx(summary(), summaryMargin())}>
              <span className={clsx(regular(), summaryMarginLeft())}>
                {fields?.autoshipDiscount?.value}
              </span>
              {calculating && <span className={skeletonLoading()}></span>}

              {!calculating && (
                <span className={clsx(regular())}>
                  {formatPrice(cartPricing?.autoshipDiscount)}
                </span>
              )}
            </div>
          )}

          {/* Coupon code field add. */}
          {pluPromotion && pluPromotion?.length > 0 && !isAnonymous && (
            <div className={clsx(summary(), summaryMargin())}>
              <span className={clsx(regular(), summaryMarginLeft())}>
                {`Coupon ${cart?.xp?.CouponCode}`}{' '}
                {pluPromotion[0].Code && (
                  <a
                    href="javascript:void(0)"
                    className={linkWithUnderline({ guest: isAnonymous })}
                    onClick={() => handlePromotionRemoveClick(pluPromotion[0].Code)}
                  >
                    remove
                  </a>
                )}
              </span>
              {calculating && <span className={skeletonLoading()}></span>}
              {!calculating && cart?.PromotionDiscount && (
                <span className={regular()}>{formatPrice(cartPricing?.promoDiscounts ?? 0)}</span>
              )}
            </div>
          )}

          {/* Subtotal */}
          {fields?.subTotal && (
            <div className={clsx(summary(), summaryBorder(), summaryMargin(), 'pt-[10px]')}>
              <span className={clsx(bold())}>{fields?.subTotal?.value}</span>

              {calculating && <span className={skeletonLoading()}></span>}
              {!calculating && (
                <span className={bold()}>{formatPrice(cartPricing?.subTotal ?? 0)}</span>
              )}
            </div>
          )}
          <div className={clsx(summaryMargin())}>
            {/* Delivery Fee */}
            {delivery && (
              <div className={clsx(summary(), summaryMargin())}>
                <span className={clsx(regular(), summaryMarginLeft())}>
                  {fields?.deliveryFee?.value}
                </span>
                {calculating && <span className={skeletonLoading()}></span>}
                {!calculating && (
                  <span className={clsx(regular())}>
                    {deliveryFee === '0.00' ? getDictionaryValue('Free') : '$' + deliveryFee}
                  </span>
                )}
              </div>
            )}

            {/* CO Delivery Fee */}
            {delivery && productlineitems[0]?.ShippingAddress?.State == 'CO' && (
              <div className={clsx(summary(), summaryMargin())}>
                <span className={clsx(regular(), summaryMarginLeft())}>
                  {fields?.coRetailDelivery?.value}
                </span>
                {calculating && <span className={skeletonLoading()}></span>}
                {!calculating && (
                  <span className={clsx(regular())}>{formatPrice(retailDeliveryFee)}</span>
                )}
              </div>
            )}

            {/* Estimated Tax */}
            {fields?.estimatedTax && (
              <div className={clsx(summary(), summaryMargin())}>
                <span className={clsx(regular(), summaryMarginLeft())}>
                  {fields?.estimatedTax?.value}
                </span>
                {calculating && <span className={skeletonLoading()}></span>}
                {!calculating && (
                  <span className={clsx(regular())}>
                    {formatPrice((cart?.TaxCost ?? 0) - retailDeliveryFee)}
                  </span>
                )}
              </div>
            )}

            {/* Delivery Tip */}
            {delivery && tiplineitem && hideDeliveryTipping !== 1 && (
              <div className={clsx(summary(), summaryMargin(), 'flex', 'items-start')}>
                <span
                  className={clsx(
                    regular(),

                    'flex items-center',
                    summaryMarginLeft(),
                    wrapIcon()
                  )}
                >
                  {fields?.deliveryTips?.value}
                  <span className="relative flex">
                    <IconHelper
                      icon={'info'}
                      className={clsx(icon())}
                      onMouseEnter={() => {
                        setShowTooltip(true);
                      }}
                      onMouseLeave={() => {
                        setShowTooltip(false);
                      }}
                    />
                    {showTooltip && (
                      <div className={topTooltipWrapper()}>
                        <div className={tooltipContainer()}>
                          <div className={topTooltipArrowFirstDiv()}></div>
                          <div className={topTooltipArrowSecondDiv()}></div>
                          <p className={topToolTipDescription()}>
                            {fields?.deliveryTipInfo?.value ||
                              'Delivery tips can be added or edited during checkout.'}
                          </p>
                        </div>
                      </div>
                    )}
                  </span>
                </span>
                {calculating && <span className={skeletonLoading()}></span>}
                {!calculating && (
                  <span className={clsx(regular())}>
                    ${Number(tiplineitem?.xp?.TipAmount?.toFixed(2) ?? 0).toFixed(2)}
                  </span>
                )}
              </div>
            )}
          </div>

          {/* Total */}
          {fields?.total && (
            <div className={clsx(summary(), summaryBorder(), 'pt-[10px]')}>
              <span
                className={clsx(
                  bold(),
                  summaryMargin(),
                  '!text-body-large-bold !font-body-large-bold !leading-body-large-bold'
                )}
              >
                {fields?.total?.value}
              </span>
              {calculating && <span className={skeletonLoading()}></span>}
              {!calculating && (
                <span
                  className={
                    (bold(), '!text-body-large-bold !font-body-large-bold !leading-body-large-bold')
                  }
                >
                  {formatPrice(cart?.Total ?? 0)}
                </span>
              )}
            </div>
          )}

          {/* Loader */}
          {calculating && (
            <div
              className={loadingImageWrapper()}
              style={{ top: 'calc(50% - 1rem)', left: 'calc(50% - 1rem)' }}
            >
              <Loader />
            </div>
          )}
        </div>

        {isShoppingCartPage && (
          <div className={clsx(block(), 'mt-[24px]')}>
            <ButtonHelper
              isLinkfield={false}
              size={'default'}
              variant={'primary'}
              onClickHandler={() => handleUserCheck(fields?.continueLink?.value?.href ?? '')}
              isDisbaled={!passesPreflightCheck}
              className="block w-full text-center !px-0"
              field={{
                value: fields?.continueLink?.value?.text as string,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderSummary;
