//global
import React, { useEffect, useState } from 'react';
import {
  Field,
  NextImage,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import { LineItem, Me } from 'ordercloud-javascript-sdk';
import BVPLPRating from 'components/BazarVoice/BVPLPRating';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import { useOcSelector } from 'src/redux/ocStore';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import {
  myAutoshipVariants,
  paymentMethodVariants,
} from 'tailwindVariants/components/myAutoshipTailwindVariant';
import { useBreakpoints } from 'src/utils/breakpoints';
import { apiRequest } from 'src/utils/apiWrapper';
import { GetStoreDetailsReturn } from 'src/pages/api/store/get-store-details-for-autoship';
import { DiscreteLineItem, getProductDetailPath } from 'src/helpers/Constants';
import { useProductPriceForUI } from 'src/hooks/useProductPrice';
import useDictionary from 'src/hooks/useDictionary';
import { BuyerProductWithXp, LineItemWithXp, SubscriptionWithXp } from 'src/redux/xp';
import { useTheme } from 'lib/context/ThemeContext';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import Loader from 'components/Loader/Loader';
import { DateTime } from 'luxon';
import { ShorthandTimeZone, getFullTimeZone } from 'src/helpers/getFullTimeZone';
import Link from 'next/link';
import { storeApi } from 'src/utils/nextApiConfig';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';
//type
export type MyAutoshipProps = ComponentProps & {
  fields: {
    cancelText?: Field<string>;
    expiresText?: Field<string>;
    creditCardEndingText?: Field<string>;
    changePaymentOrAddressText?: Field<string>;
    deliveryAddress?: Field<string>;
    deliveryTip?: Field<string>;
    nextDeliveryDate?: Field<string>;
    noAutoshipText?: Field<string>;
    orderedFrom?: Field<string>;
    paymentMethod?: Field<string>;
    pricingDiscountText?: Field<string>;
    reasonForCancellation?: Field<string>;
    autoshipOrderTitle?: Field<string>;
    cancelDateTitle?: Field<string>;
    title?: Field<string>;
    skipDeliveryText?: Field<string>;
    cancelAutoshipText?: Field<string>;
    assistanceText?: Field<string>;
  };
};
export type newSubscription = SubscriptionWithXp & {
  tipDetails?: {
    LineTotal?: number;
    LineSubtotal?: number;
    xp?: {
      TipAmount?: number;
    };
  };
  lineItems?: LineItem[];
};

interface storeDataType {
  storeId?: string;
  storeName?: string;
  phone?: string;
  timezone?: string;
  autoshipSettings?: {
    daysBeforeSubscriptionChangesAllowed?: number;
  };
}
//main component
const MyAutoship: React.FC<MyAutoshipProps> = ({ fields, params }) => {
  const [subscriptions, setSubscriptions] = useState<newSubscription[]>();
  const { getDictionaryValue } = useDictionary();
  const [isLoading, setIsLoading] = useState(true);
  const [otherStoreDetails, setOtherStoreDetails] = useState<storeDataType[]>([]);
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const wishlistData = useOcSelector((s) => s.wishlist?.wishlist);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const getSubscriptions = async () => {
    let hasNext = true;
    let page = 1;
    let results: newSubscription[] = [];
    do {
      const subscriptions = await Me.ListSubscriptions<newSubscription>({ pageSize: 100, page });
      results = [...results, ...subscriptions.Items];
      page++;
      if (subscriptions.Meta.TotalPages <= page) {
        hasNext = false;
      }
    } while (hasNext);

    const otherStoresDetails: string[] = [];
    const subscriptionItems = await Promise.all(
      results

        ?.sort((a, b) => {
          const aMS = getExpectedDeliveryDate(a).toMillis();
          const bMS = getExpectedDeliveryDate(b).toMillis();
          return bMS - aMS;
        })
        ?.sort((a, b) => (b.Active === a.Active ? 0 : b.Active ? 1 : -1))
        ?.map(async (subscription) => {
          if (
            myStoreData?.storeId != subscription?.ToCompanyID &&
            !otherStoresDetails.includes(subscription?.ToCompanyID ?? '') &&
            subscription?.ToCompanyID?.length === 4
          ) {
            otherStoresDetails.push(subscription?.ToCompanyID);
          }
          const subscriptionItems = await Me.ListSubscriptionItems<LineItemWithXp>(
            subscription?.ID ?? '',
            { pageSize: 100 }
          );
          const tipDetails = subscriptionItems.Items.find(
            (item) => item.ProductID === DiscreteLineItem.TIP
          );
          if (tipDetails) {
            subscription.tipDetails = tipDetails;
          } else {
            const lineId = `${subscription.ID}-TIP`;
            subscription.tipDetails = await Me.SaveSubscriptionItem(
              subscription?.ID ?? '',
              lineId,
              {
                ProductID: DiscreteLineItem.TIP,
                Quantity: 1,
                UnitPrice: 0,
                xp: {
                  TipIsRecurring: true,
                  TipAmount: 0,
                },
              }
            );
          }
          subscription.lineItems = subscriptionItems?.Items?.filter(
            (item) => item.ProductID !== DiscreteLineItem.TIP
          );
          return subscription;
        })
    );

    if (otherStoresDetails.length > 0) {
      const storeDetails = await Promise.all(
        otherStoresDetails?.map(async (storeId) => {
          const data = await apiRequest<GetStoreDetailsReturn>(
            `${storeApi.getStoreDetailsForAutoship}?${new URLSearchParams({
              storeId,
            }).toString()}`,
            {
              method: 'GET',
            }
          );
          return data?.data as storeDataType;
        })
      );
      setOtherStoreDetails(storeDetails);
    }
    setSubscriptions(subscriptionItems);
    setIsLoading(false);
  };
  useEffect(() => {
    if (myStoreData?.storeId) {
      getSubscriptions();
    }
    // load BV script
    loadBazaarVoiceScript();
  }, [myStoreData?.storeId]);
  useEffect(() => {
    if (autoshipContextData?.updatedNextDeliveryDate && autoshipContextData?.subscriptionId) {
      const newSubscriptions = subscriptions?.map((subscription) => {
        if (subscription.ID === autoshipContextData?.subscriptionId) {
          const newSubscriptionData = subscription;
          newSubscriptionData.NextOrderDate = autoshipContextData?.updatedNextDeliveryDate;
          return newSubscriptionData;
        }
        return subscription;
      });
      setSubscriptions(newSubscriptions);
      setAutoshipContextData({
        ...autoshipContextData,
        updatedNextDeliveryDate: false,
        subscriptionId: false,
      });
    }
  }, [autoshipContextData?.updatedNextDeliveryDate, autoshipContextData?.subscriptionId]);
  useEffect(() => {
    if (
      autoshipContextData?.cancelledAutoshipId &&
      autoshipContextData?.autoshipEndDate &&
      autoshipContextData?.autoshipCancellationReason
    ) {
      const newSubscriptions = subscriptions?.map((subscription) => {
        if (subscription.ID === autoshipContextData.cancelledAutoshipId) {
          const newSubscriptionData = subscription;
          newSubscriptionData.Active = false;
          newSubscriptionData.xp = newSubscriptionData.xp ?? {};
          newSubscriptionData.xp.CancellationReason =
            autoshipContextData?.autoshipCancellationReason;
          newSubscriptionData.EndDate = autoshipContextData?.autoshipEndDate;
          return newSubscriptionData;
        }
        return subscription;
      });
      setSubscriptions(
        newSubscriptions?.sort((a: newSubscription, b: newSubscription) =>
          b.Active === a.Active ? 0 : b.Active ? 1 : -1
        )
      );
      setAutoshipContextData({
        ...autoshipContextData,
        cancelledAutoshipId: false,
        autoshipEndDate: false,
        autoshipCancellationReason: false,
      });
    }
  }, [
    autoshipContextData?.cancelledAutoshipId,
    autoshipContextData?.autoshipEndDate,
    autoshipContextData?.autoshipCancellationReason,
  ]);
  useEffect(() => {
    if (autoshipContextData?.subscriptionIdForTipChange) {
      const newSubscriptions = subscriptions?.map((subscription) => {
        if (subscription.ID === autoshipContextData?.subscriptionIdForTipChange) {
          const newSubscriptionData = subscription;
          if (newSubscriptionData.tipDetails && newSubscriptionData.tipDetails?.xp) {
            newSubscriptionData.tipDetails.xp.TipAmount = autoshipContextData.autoshipTipAmount;
            newSubscriptionData.tipDetails.LineTotal = autoshipContextData.autoshipTipAmount;
            newSubscriptionData.tipDetails.LineSubtotal = autoshipContextData.autoshipTipAmount;
          } else {
            newSubscriptionData.tipDetails = {
              LineTotal: autoshipContextData.autoshipTipAmount,
              LineSubtotal: autoshipContextData.autoshipTipAmount,
              xp: {
                TipAmount: autoshipContextData.autoshipTipAmount,
              },
            };
          }
          return newSubscriptionData;
        }
        return subscription;
      });
      setSubscriptions(newSubscriptions);
      setAutoshipContextData({
        ...autoshipContextData,
        autoshipTipAmount: false,
        subscriptionIdForTipChange: false,
      });
    }
  }, [autoshipContextData?.autoshipTipAmount, autoshipContextData?.subscriptionIdForTipChange]);

  interface BVObject {
    reviews: {
      on: (event: string, callback: () => void) => void;
    };
  }

  // Define the bvCallback function
  const bvCallback = (BV: BVObject): void => {
    // Register a function to be called when a BV feature needs to display the R&R element, such as Rating Summary's stars
    BV.reviews.on('show', () => {
      // If the R&R container is hidden (such as behind a tab), put code here to make it visible (open the tab)
      const buttons = document.getElementsByClassName('bv_war_button');
      if (buttons && buttons.length > 0) {
        (buttons[0] as HTMLElement).click(); // Using type assertion to avoid compilation errors
      }
    });
  };

  const { themeNameUpper } = useTheme();
  const loadBazaarVoiceScript = () => {
    const script = document.createElement('script');
    script.src =
      themeNameUpper === 'PSP'
        ? process.env.NEXT_PUBLIC_BAZAAR_VOICE_URL || ''
        : process.env.NEXT_PUBLIC_WNW_BAZAAR_VOICE_URL || '';

    script.async = true;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    script.onload = () => {};
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).bvCallback = bvCallback;
    }
    // Append the script to the document body
    document.body.appendChild(script);
    // remove the script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  };

  const {
    base,
    heartIconWithTitle,
    title,
    productsDetailWrapper,
    productListNote,
    productsListWrapper,
    noAutoshipMessage,
    subscriptionsWrapper,
    subscriptionsWrappers,
    orderDetailWrapper,
    subscriptionTopContentWrapper,
    subscriptionTitle,
    subscriptionStatus,
  } = myAutoshipVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    paymentBase,
    productImage,
    paymentMethodTitle,
    paymentMethodSubTitle,
    paymentMethodDescription,
    deliveryTip,
    editIcon,
    address,
    phoneNumberWrapper,
    nextDeliveryWrapper,
    buttonWrapper,
    skipButton,
    buttonHelper,
    cancellationWrapper,
    cancellationTitle,
    cancellationSubTitle,
    priceWrapper,
    price,
    writeAReview,
    productWrapper,
    productImageWrapper,
    productName,
    pricesWrapper,
    qtyWrapper,
  } = paymentMethodVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { isMobile } = useBreakpoints();
  const renderPaymentMethod = (subscription: newSubscription) => (
    <div className={paymentBase()}>
      <RichText className={paymentMethodTitle()} field={fields?.paymentMethod} />
      <div className={paymentMethodSubTitle()}>
        {subscription && subscription?.xp?.CardEndingIn ? (
          <>
            <RichText field={fields?.creditCardEndingText} />
            <p>{subscription?.xp?.CardEndingIn}</p>
          </>
        ) : (
          <></>
        )}
        {subscription?.xp?.CardExpiry && (
          <RichTextHelper
            field={fields?.expiresText}
            updatedField={{
              value: `${fields?.expiresText?.value} ${subscription?.xp?.CardExpiry}`,
            }}
          />
        )}
      </div>
      <RichText field={fields?.changePaymentOrAddressText} className={paymentMethodDescription()} />
    </div>
  );
  const renderDeliveryTip = (subscription: newSubscription) => (
    <div className={paymentBase()}>
      <RichText className={paymentMethodTitle()} field={fields?.deliveryTip} />
      <div className={deliveryTip()}>
        <b>
          {getDictionaryValue('CurrencySymbol')}
          {subscription?.tipDetails?.xp?.TipAmount
            ? subscription?.tipDetails?.xp?.TipAmount?.toFixed(2)
            : '0.00'}
        </b>
        <IconHelper
          onClick={() => {
            setAutoshipContextData({
              ...autoshipContextData,
              openTipModal: true,
              subscriptionData: subscription,
            });
          }}
          className={editIcon()}
          icon={'icon-edit'}
        />
      </div>
    </div>
  );
  const renderDeliveryAddress = (subscription: newSubscription) => (
    <div className={paymentBase()}>
      <RichText field={fields?.deliveryAddress} className={paymentMethodTitle()} />
      <div className={address()}>
        <p>{subscription?.xp?.DeliveryAddress?.Street1}</p>
        {(subscription?.xp?.DeliveryAddress?.City || subscription?.xp?.DeliveryAddress?.State) && (
          <p>
            {subscription?.xp?.DeliveryAddress?.City}, {subscription?.xp?.DeliveryAddress?.State}
          </p>
        )}
        <p>{subscription?.xp?.DeliveryAddress?.Zip}</p>
      </div>
    </div>
  );
  const renderOrderedFrom = (subscription: newSubscription) => {
    const currentStore =
      myStoreData?.storeId === subscription?.ToCompanyID
        ? myStoreData
        : otherStoreDetails?.find(
            (store: storeDataType) => store.storeId == subscription?.ToCompanyID
          );
    return (
      <div className={paymentBase()}>
        <RichText field={fields?.orderedFrom} className={paymentMethodTitle()} />
        <p className={address()}>{currentStore?.storeName}</p>
        {currentStore?.phone && (
          <LinkHelper
            className={phoneNumberWrapper()}
            field={{
              value: {
                text: currentStore?.phone,
                title: currentStore?.phone,
                href: `tel:${currentStore?.phone}`,
              },
            }}
          />
        )}
      </div>
    );
  };

  const renderNextDeliveryDetails = (subscription: newSubscription) => {
    const freq = myStoreData.autoshipSettings?.subscriptionFrequency.find(
      (x) => x.days === subscription?.Frequency
    );

    const freqText =
      freq?.label ??
      `${getDictionaryValue('EveryText')} ${
        subscription?.Interval === 'Days' && subscription?.Frequency
          ? Math.floor(subscription?.Frequency / 7)
          : subscription?.Frequency
      } ${
        subscription?.Interval === 'Days' ? getDictionaryValue('WeeksText') : subscription?.Interval
      }`;

    return (
      <div className={nextDeliveryWrapper()}>
        {subscription?.NextOrderDate && (
          <RichTextHelper
            field={fields?.nextDeliveryDate}
            updatedField={{
              value: `${fields?.nextDeliveryDate?.value} ${getExpectedDeliveryDate(
                subscription
              ).toFormat('MM/dd/yyyy')}`,
            }}
            className={paymentMethodTitle()}
          />
        )}
        <p className={address()}>{`${freqText}`}</p>
        {!isMobile && renderButtons(subscription)}
      </div>
    );
  };

  function getExpectedDeliveryDate(subscription?: newSubscription) {
    // Should never happen but to satify TypeScript
    if (!subscription) {
      return DateTime.now();
    }

    const nextOrderDate = DateTime.fromISO(subscription.NextOrderDate ?? '');

    // Newer subscriptions should have ExpectedDeliveryDate set on the back-end.
    if (subscription.xp?.ExpectedDeliveryDate) {
      const expectedDeliveryDate = DateTime.fromISO(subscription.xp?.ExpectedDeliveryDate ?? '');
      // Next Order Date is after expectedDeliveryDate, meaning that ExpectedDeliveryDate is incorrect
      // This can happen if NextOrderDate was manually updated.
      if (nextOrderDate.diff(expectedDeliveryDate, 'days').days > 1) {
        return nextOrderDate;
      }
      return expectedDeliveryDate;
    }
    return nextOrderDate;
  }

  function shouldShowButton(
    subscription: newSubscription,
    timezone?: string,
    daysBeforeSubscriptionChangesAllowed?: number
  ) {
    // This is the value with fallback that should be used in the UI.
    const expectedDeliveryDate = getExpectedDeliveryDate(subscription);

    // Get the actual value without fallback
    const expectedDeliveryDateFromXp = DateTime.fromISO(
      subscription.xp?.ExpectedDeliveryDate ?? ''
    );

    const nextOrderDate = DateTime.fromISO(subscription.NextOrderDate ?? '');
    // Next order date is after expected delivery date.
    // This means subscription.xp?.ExpectedDeliveryDate didn't get set correctly
    if (nextOrderDate.diff(expectedDeliveryDateFromXp, 'days').days > 0) {
      // So show button so user can skip or cancel
      return true;
    }
    if (subscription.xp?.ExpectedDeliveryDate) {
      // subscription.xp?.ExpectedDeliveryDate is in the past meaning that the order isn't scheduled.
      // This should only happen if there were errors trigger the subscription.
      if (DateTime.now().diff(expectedDeliveryDateFromXp, 'days').days > 0) {
        // So show button so user can skip or cancel
        return true;
      }
    }
    // If there's an adjustment date, use that.
    if (subscription.xp?.AdjustmentDate) {
      const adjustmentDate = DateTime.fromISO(subscription.xp?.AdjustmentDate);
      const diff = adjustmentDate.diff(DateTime.now(), 'days');
      return diff.days > 0;
    }
    // Backup logic if subscription.xp?.AdjustmentDate isn't set
    if (expectedDeliveryDate && timezone && daysBeforeSubscriptionChangesAllowed) {
      // Get the full time zone name
      const fullTimeZone = getFullTimeZone(timezone as ShorthandTimeZone) || timezone;

      // Get the current date and time in the store's time zone
      const currentDate = DateTime.now().setZone(fullTimeZone);

      // Parse the next delivery date and set it to the store's time zone
      const deliveryDate = expectedDeliveryDate.setZone(fullTimeZone);

      // Calculate the difference in days
      const differenceInDays = deliveryDate.diff(currentDate, 'days').days;

      // Check if the difference is greater than the allowed modification days
      if (differenceInDays > daysBeforeSubscriptionChangesAllowed) {
        return true; // Modification is allowed
      } else {
        return false; // Modification is not allowed
      }
    } else {
      return false; // Modification is not allowed
    }
  }
  const renderButtons = (subscription: newSubscription) => {
    const currentStore =
      myStoreData?.storeId === subscription?.ToCompanyID
        ? myStoreData
        : otherStoreDetails?.find(
            (store: storeDataType) => store.storeId == subscription?.ToCompanyID
          );

    const showButton = shouldShowButton(
      subscription,
      currentStore?.timezone as string,
      currentStore?.autoshipSettings?.daysBeforeSubscriptionChangesAllowed
    );
    return (
      <div
        className={buttonWrapper({
          className: 'flex flex-col-reverse lg:flex-col',
        })}
      >
        <div className="flex flex-col gap-2">
          <LinkHelper
            className={skipButton({
              isDisabled: !showButton,
            })}
            field={{
              value: {
                text: fields?.skipDeliveryText?.value,
                href: '/',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              if (showButton) {
                setAutoshipContextData({
                  ...autoshipContextData,
                  isSkipClicked: true,
                  subscriptionData: subscription,
                });
              }
            }}
          />
          <LinkHelper
            className={skipButton({
              isDisabled: !showButton,
            })}
            onClick={(e) => {
              e.preventDefault();
              if (showButton) {
                setAutoshipContextData({
                  ...autoshipContextData,
                  isCancelClicked: true,
                  subscriptionData: subscription,
                });
              }
            }}
            field={{
              value: {
                text: fields?.cancelAutoshipText?.value,
                href: '/',
              },
            }}
          />
        </div>
        {!showButton && (
          <RichTextHelper
            className={buttonHelper()}
            field={fields?.assistanceText}
            updatedField={{
              value: `${fields?.assistanceText?.value?.replace(
                '{phone}',
                `<a aria-label="phone-number" href="tel:${currentStore?.phone}">${currentStore?.phone}</a>`
              )}`,
            }}
          />
        )}
      </div>
    );
  };
  const renderCancellationDetails = (subscription: newSubscription) => {
    return (
      <div className={cancellationWrapper()}>
        <div>
          <RichText className={cancellationTitle()} field={fields?.reasonForCancellation} />
          <RichTextHelper
            field={fields?.cancelText}
            updatedField={{
              value: `${fields?.cancelText?.value} ${subscription?.xp?.CancellationReason}`,
            }}
            className={cancellationSubTitle()}
          />
        </div>
        {subscription?.EndDate && (
          <RichTextHelper
            field={fields?.cancelDateTitle}
            updatedField={{
              value: `${fields?.cancelDateTitle?.value} ${DateTime.fromISO(
                subscription?.EndDate
              ).toFormat('MM/dd/yyyy')}`,
            }}
            className={cancellationSubTitle()}
          />
        )}
      </div>
    );
  };
  const renderProductDetails = (subscription: newSubscription) => (
    <div className={productsListWrapper()}>
      {subscription?.lineItems &&
        subscription?.lineItems?.length > 0 &&
        subscription?.lineItems?.map((product, index) => {
          const ImageData = getFirstProductImage(product?.Product?.xp?.Images);
          const productUrl = getProductDetailPath(product?.Product as BuyerProductWithXp);
          return (
            <div className={productWrapper()} key={index}>
              <div className="relative">
                <IconHelper
                  className={heartIconWithTitle()}
                  onClick={() => {
                    if (product?.Product?.ID) {
                      dispatch(
                        updateProductInWishlist({
                          product: product?.Product,
                        })
                      );
                    }
                  }}
                  icon={
                    product?.Product?.ID &&
                    wishlistData &&
                    isProductInWishlist(wishlistData, product?.Product?.ID)
                      ? 'heart'
                      : 'heart-stroke'
                  }
                />
                <div className="flex lg:block">
                  <div className={productImageWrapper()}>
                    <Link prefetch={false} aria-label="product-link" href={productUrl}>
                      <NextImage
                        field={{
                          value: {
                            src: ImageData?.Url || dummyProductImage?.jsonValue?.value?.src,
                            width: '140',
                            height: '140',
                            alt: ImageData?.Name || dummyProductImage?.jsonValue?.value?.alt,
                          },
                        }}
                        className={productImage()}
                      />
                    </Link>
                  </div>

                  <div className="w-full">
                    <Link
                      aria-label="product-link"
                      prefetch={false}
                      href={productUrl}
                      className="no-underline hover:no-underline"
                    >
                      <Text
                        tag="p"
                        className={productName()}
                        field={{
                          value: product?.Product?.Name,
                        }}
                      />
                    </Link>
                    {isMobile && product?.Product?.ID && (
                      <div className="mt-6">
                        <RenderOtherProductDetails
                          subscription={subscription}
                          lineItems={product}
                          productId={product?.Product.ID}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!isMobile && product?.Product?.ID && (
                <RenderOtherProductDetails
                  subscription={subscription}
                  productId={product?.Product.ID}
                  lineItems={product}
                />
              )}
            </div>
          );
        })}
    </div>
  );

  const calculateDiscountedPrice = (price: number, subsequentOrdersDiscount: number) => {
    // Ensure the discount value is between 0 and 100
    if (subsequentOrdersDiscount < 0 || subsequentOrdersDiscount > 100) {
      throw new Error('subsequentOrdersDiscount should be between 0 and 100');
    }

    // Calculate the discount amount
    const discountAmount = (price * subsequentOrdersDiscount) / 100;

    // Subtract the discount from the original price
    return (price - discountAmount)?.toFixed(2);
  };

  const RenderOtherProductDetails = ({
    subscription,
    lineItems,
    productId,
  }: {
    subscription: newSubscription;
    lineItems: LineItem;
    productId: string;
  }) => {
    const prices = useProductPriceForUI(productId, subscription?.ToCompanyID);
    const productUrl = getProductDetailPath(lineItems?.Product as BuyerProductWithXp);
    const [showIMapPriceTooltip, setShowIMapPriceTooltip] = useState<string | false>(false);
    const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

    return (
      <>
        {lineItems?.Product && lineItems?.Product.ID && (
          <div>
            <div className={pricesWrapper()}>
              <p className={qtyWrapper()}>
                {getDictionaryValue('QuantityPDP')}&nbsp;{lineItems?.Quantity}
              </p>
              <div>
                {!prices?.hasListandMemberPrice ? (
                  <div>
                    <div>
                      <div className={priceWrapper()}>
                        <p>{getDictionaryValue('ListPrice')}</p>
                        <p
                          className={price({
                            strike:
                              (subscription?.Active &&
                                lineItems?.Product?.xp?.ReccuringAutoshipDiscount) ||
                              prices?.hasImapPrice ||
                              (prices?.listPrice != null &&
                                prices?.imapPrice != null &&
                                prices?.memberPrice != null &&
                                prices.listPrice > prices.imapPrice &&
                                prices.imapPrice > prices.memberPrice),
                          })}
                        >
                          {getDictionaryValue('CurrencySymbol')}
                          {prices?.hasImapPrice
                            ? prices?.imapPrice
                            : prices?.hasListPrice
                            ? prices?.listPrice
                            : ''}
                        </p>
                      </div>
                      {(prices?.hasImapPrice ||
                        (prices?.listPrice != null &&
                          prices?.imapPrice != null &&
                          prices?.memberPrice != null &&
                          prices.listPrice > prices.imapPrice &&
                          prices.imapPrice > prices.memberPrice)) && (
                        <div>
                          <b
                            className={price({
                              showPriceInRed: true,
                            })}
                          >
                            {getDictionaryValue('ViewPriceInCartPDP')}
                          </b>
                          <div
                            className={'relative'}
                            onMouseEnter={() => {
                              if (prices?.productId) {
                                setShowIMapPriceTooltip(prices?.productId);
                              }
                            }}
                            onMouseLeave={() => {
                              setShowIMapPriceTooltip(false);
                            }}
                          >
                            <b
                              className={price({
                                className: 'underline',
                              })}
                            >
                              {getDictionaryValue('WhyWeDoThisPDP')}
                            </b>
                            {showIMapPriceTooltip && (
                              <div className={topTooltipWrapper()}>
                                <div className={tooltipContainer()}>
                                  <div className={topTooltipArrowFirstDiv()}></div>
                                  <div className={topTooltipArrowSecondDiv()}></div>
                                  <p className={topToolTipDescription()}>
                                    {getDictionaryValue('WhyWeDoThisTooltipPDP')}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  // This will render if hasListandMemberPrice is true (Determines if listPrice price is available and greater than PPC and PPC is greater then IMAP prices)
                  <div>
                    <div className={priceWrapper()}>
                      <p>{getDictionaryValue('ListPrice')}</p>
                      <span
                        className={price({
                          strike: true,
                        })}
                      >
                        {getDictionaryValue('CurrencySymbol')}
                        {prices?.listPrice}
                      </span>
                    </div>
                    <div
                      className={priceWrapper({
                        hidePriceWrapper: prices?.listPrice === prices?.memberPrice,
                      })}
                    >
                      <p>{getDictionaryValue('MemberPricePDP')}</p>
                      <span
                        className={price({
                          showPriceInRed: prices?.listPrice != prices?.memberPrice,
                          strike:
                            subscription?.Active &&
                            lineItems?.Product?.xp?.ReccuringAutoshipDiscount,
                        })}
                      >
                        {getDictionaryValue('CurrencySymbol')}
                        {prices?.memberPrice}
                      </span>
                    </div>
                  </div>
                )}
                {!(
                  prices?.hasImapPrice ||
                  (prices?.listPrice != null &&
                    prices?.imapPrice != null &&
                    prices?.memberPrice != null &&
                    prices.listPrice > prices.imapPrice &&
                    prices.imapPrice > prices.memberPrice)
                ) && (
                  <div
                    className={priceWrapper({
                      hidePriceWrapper:
                        !lineItems?.Product?.xp?.ReccuringAutoshipDiscount ||
                        !subscription?.Active ||
                        prices?.hasImapPrice ||
                        (prices?.listPrice != null &&
                          prices?.imapPrice != null &&
                          prices?.memberPrice != null &&
                          prices.listPrice > prices.imapPrice &&
                          prices.imapPrice > prices.memberPrice),
                    })}
                  >
                    <p>{getDictionaryValue('AutoshipPrice')}</p>
                    <p
                      className={price({
                        isAutoshipPrice:
                          subscription?.Active &&
                          lineItems?.Product?.xp?.ReccuringAutoshipDiscount &&
                          !!myStoreData?.autoshipSettings?.subsequentOrdersDiscount,
                      })}
                    >
                      {getDictionaryValue('CurrencySymbol')}
                      {prices?.memberPrice &&
                        myStoreData?.autoshipSettings?.subsequentOrdersDiscount &&
                        calculateDiscountedPrice(
                          prices?.memberPrice,
                          myStoreData?.autoshipSettings?.subsequentOrdersDiscount
                        )}
                    </p>
                  </div>
                )}
                {!prices?.hasListandMemberPrice &&
                !(
                  prices?.hasImapPrice ||
                  (prices?.listPrice != null &&
                    prices?.imapPrice != null &&
                    prices?.memberPrice != null &&
                    prices.listPrice > prices.imapPrice &&
                    prices.imapPrice > prices.memberPrice)
                ) ? (
                  <div className="h-6"></div> //rendering empty div to balance out css
                ) : (
                  <></>
                )}
              </div>
            </div>
            <BVPLPRating
              showLeftAlign={true}
              bvProductId={lineItems?.Product?.xp?.UPC}
              bvRedirectUrl={productUrl || ''}
            />
            <Link
              aria-label="product-link"
              href={productUrl}
              prefetch={false}
              className={writeAReview()}
            >
              {getDictionaryValue('WriteReviewPDP')}
            </Link>
          </div>
        )}
      </>
    );
  };
  const renderSubscriptions = (subscriptions: newSubscription[]) =>
    subscriptions?.map((subscription: newSubscription) => (
      <div key={subscription.ID} className={subscriptionsWrappers()}>
        <div className={subscriptionsWrapper()}>
          <div className={subscriptionTopContentWrapper()}>
            <RichTextHelper
              tag="p"
              className={subscriptionTitle()}
              field={fields?.autoshipOrderTitle}
              // TODO: change this static value and ID
              updatedField={{ value: `${fields?.autoshipOrderTitle?.value} #${subscription?.ID}` }}
            />
            <p
              className={subscriptionStatus({
                isActive: subscription?.Active,
              })}
            >
              {subscription?.Active
                ? getDictionaryValue('Active')
                : getDictionaryValue('Cancelled')}
            </p>
          </div>
          <div className={orderDetailWrapper()}>
            {subscription?.Active ? (
              <>
                {isMobile && renderOrderedFrom(subscription)}
                {renderPaymentMethod(subscription)}
                {renderDeliveryTip(subscription)}
                {renderDeliveryAddress(subscription)}
                {!isMobile && renderOrderedFrom(subscription)}
                {renderNextDeliveryDetails(subscription)}
              </>
            ) : (
              <>{renderCancellationDetails(subscription)}</>
            )}
          </div>
        </div>
        <div className={productsDetailWrapper()}>
          <RichText tag="p" className={productListNote()} field={fields?.pricingDiscountText} />
          {renderProductDetails(subscription)}
          {isMobile && subscription?.Active && renderButtons(subscription)}
        </div>
      </div>
    ));
  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={base({ className: params?.Style ?? '' })}>
      <Text className={title()} tag="p" field={fields?.title} />
      {!isLoading ? (
        <>
          {subscriptions && subscriptions.length > 0 ? (
            renderSubscriptions(subscriptions)
          ) : (
            <RichText className={noAutoshipMessage()} field={fields?.noAutoshipText} />
          )}
        </>
      ) : (
        <div className={noAutoshipMessage()}>
          <Loader />
        </div>
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyAutoshipProps>(MyAutoship);
