import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import CheckboxField from 'src/helpers/Form/CheckboxField';
import DropdownField from 'src/helpers/Form/DropdownField';
import LocationDropdownField from 'src/helpers/Form/LocationDropdownField';
import PhoneField from 'src/helpers/Form/PhoneField';
import TextAreaField from 'src/helpers/Form/TextAreaField';
import TextField from 'src/helpers/Form/TextField';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import useDictionary from 'src/hooks/useDictionary';
import { apiRequest } from 'src/utils/apiWrapper';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { jobInquiryFormTailwindVariant } from 'tailwindVariants/components/jobInquiryFormTailwindVariant';

export type FormPropsType = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          consentMessage?: {
            jsonValue: Field<string>;
          };
          title?: Field<string>;
          shortDescription?: Field<string>;
          submitButtonText?: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          submitEndpoint?: {
            jsonValue: Field<string>;
          };
          successMessage?: Field<string>;
          failureMessage?: Field<string>;
        };
        jobtime?: {
          items: { targetItems: Array<OptionData> };
        };
        weekdays?: {
          items: { targetItems: Array<OptionData> };
        };
        yesno?: {
          items: { targetItems: Array<OptionData> };
        };
        states?: {
          regions: {
            targetItems: State[];
          };
        };
        location: {
          children: {
            results: Array<LocationChild>;
          };
        };
      };
    };
  };

interface LocationChild {
  hasChildren: boolean;
  name: string;
  children: { results?: Array<LocationChild> };
}
interface OptionData {
  text: Field<string>;
  value: Field<string>;
}
interface State {
  code: { value: string };
  name: { value: string };
}
interface FormValuesTypes {
  [key: string]: string;
}

const JobInquiryForm = ({ fields }: FormPropsType): JSX.Element => {
  const {
    formContainer,
    form,
    fieldWrap,
    title,
    contentTitleSection,
    formWrapper,
    submitBtn,
    shortDescription,
    sectionHeading,
    consentMsg,
  } = jobInquiryFormTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const initialValues = {
    Address: '',
    City: '',
    DaysAvailable: '',
    Eligibility: '',
    Email: '',
    FirstName: '',
    LastName: '',
    Store: '',
    WorkType: '',
    Phone: '',
    PositionDesired: '',
    ReferredBy: '',
    Resume: '',
    StartDate: '',
    State: '',
    ZipCode: '',
    HaveYouWorkedForPspBefore: '',
    AreYou18YearsOrOlder: '',
    Campaign: 'WS_Employment_WNW', // * These field is required to submit to the middleware call.
    MessageType: 'E', // * These field is required to submit to the middleware call. TODO: need to make this field dynamic.
  };

  const transFormFields = transformData(fields?.data?.data?.formFields);
  const { getDictionaryValue } = useDictionary();
  const [showThankYouMsg, setShowThankYouMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const token = useAuthToken();
  const { themeNameUpper } = useTheme();
  const formSubmitAction = async (formvalues: FormValuesTypes, endpointURL: string | undefined) => {
    const updatedPostData = Object.keys(formvalues).reduce((acc: FormValuesTypes, key: string) => {
      if (typeof formvalues[key] !== 'string') {
        acc[key] = formvalues[key]?.toString();
      } else {
        acc[key] = formvalues[key];
      }
      return acc;
    }, {});

    try {
      setShowErrorMsg(false);
      const endpoint = `${process.env.NEXT_PUBLIC_API_ENDPOINT}${endpointURL}`;
      const headersData = {
        Authorization: token,
        site: themeNameUpper,
      };
      const options = { method: 'POST', headers: headersData, data: updatedPostData };
      await apiRequest(endpoint, options);
      setShowThankYouMsg(true); // TODO: Need to manage globally with the status code.
    } catch (error) {
      setShowErrorMsg(true);
      throw new Error(`Error fetching ${endpointURL}: ${error}`);
    }
  };
  const handleModalClose = () => {
    setShowThankYouMsg(false);
  };
  return (
    <>
      {showThankYouMsg && (
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={showThankYouMsg}
          customPopup={true}
          popupWidth="max-w-[835px]"
        >
          <>
            <RichText className="rte" field={fields?.data?.data?.successMessage} />
          </>
        </ModalWrapper>
      )}
      {!showThankYouMsg && (
        <div className={formWrapper()}>
          <div className={contentTitleSection()}>
            {fields?.data?.data?.title?.value && (
              <Text field={fields?.data?.data?.title} className={title()} tag="h3" />
            )}
            {fields.data?.data?.shortDescription?.value && (
              <p className={shortDescription()}>{fields?.data?.data?.shortDescription?.value}</p>
            )}
          </div>
          <div className={formContainer()}>
            {showErrorMsg && (
              <div className="text-system-red">
                <RichText field={fields?.data?.data?.failureMessage} className="rte" />
              </div>
            )}
            <Formik
              initialValues={initialValues}
              onSubmit={async (
                values: FormValuesTypes,
                { setSubmitting }: FormikHelpers<FormValuesTypes>
              ) => {
                setSubmitting(true);
                await formSubmitAction(
                  values,
                  fields?.data?.data?.submitEndpoint?.jsonValue?.value
                );
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <FormikForm className={form()}>
                  <div className={fieldWrap()}>
                    <h2 className={sectionHeading()}>
                      {getDictionaryValue('JobInquiry_Section_PersonalData')}
                    </h2>
                  </div>
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields?.FirstName} />
                    <TextField {...transFormFields?.LastName} />
                  </div>
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields?.Email} />
                    <PhoneField {...transFormFields?.Phone} />
                  </div>
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields?.Address} />
                    <TextField {...transFormFields?.City} />
                  </div>
                  <div className={fieldWrap()}>
                    <DropdownField
                      {...transFormFields?.State}
                      options={fields?.data?.states?.regions?.targetItems}
                      firstOptionData={getDictionaryValue('SelectState')}
                    />
                    <TextField {...transFormFields?.ZipCode} />
                  </div>
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields?.ReferredBy} />
                  </div>
                  <div className={fieldWrap()}>
                    <LocationDropdownField
                      {...transFormFields?.Store}
                      options={fields?.data?.location?.children?.results}
                      firstOptionData={getDictionaryValue('SelectLocation')}
                    />
                  </div>
                  <div className={fieldWrap()}>
                    <DropdownField
                      {...transFormFields?.HaveYouWorkedForPspBefore}
                      options={fields?.data?.yesno?.items?.targetItems}
                    />
                  </div>
                  <div className={fieldWrap()}>
                    <DropdownField
                      {...transFormFields?.AreYou18YearsOrOlder}
                      options={fields?.data?.yesno?.items?.targetItems}
                    />
                  </div>
                  <div className={fieldWrap()}>
                    <DropdownField
                      {...transFormFields?.Eligibility}
                      options={fields?.data?.yesno?.items?.targetItems}
                    />
                  </div>
                  <div className={fieldWrap()}>
                    <h2 className={sectionHeading()}>
                      {getDictionaryValue('JobInquiry_Section_Availability')}
                    </h2>
                  </div>
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields?.PositionDesired} />
                    <TextField {...transFormFields?.StartDate} />
                  </div>
                  <div className={fieldWrap()}>
                    <DropdownField
                      {...transFormFields?.WorkType}
                      options={fields?.data?.jobtime?.items?.targetItems}
                    />
                  </div>
                  <div className={fieldWrap()}>
                    <h2 className={sectionHeading()}>{getDictionaryValue('DaysAvailable')}</h2>
                  </div>
                  <div className={fieldWrap()}>
                    {
                      <CheckboxField
                        {...transFormFields?.DaysAvailable}
                        options={fields?.data?.weekdays?.items?.targetItems}
                      />
                    }
                  </div>
                  <div className={fieldWrap()}>
                    <h2 className={sectionHeading()}>
                      {getDictionaryValue('JobInquiry_Section_Resume')}
                    </h2>
                  </div>
                  <div className={fieldWrap()}>
                    <TextAreaField {...transFormFields?.Resume} />
                  </div>
                  {fields?.data?.data?.consentMessage && (
                    <div className={fieldWrap()}>
                      <p className={consentMsg()}>
                        {fields?.data?.data?.consentMessage?.jsonValue?.value}
                      </p>
                    </div>
                  )}
                  <div>
                    <button
                      aria-label="submit"
                      className={submitBtn()}
                      type="submit"
                      data-disabled={isSubmitting}
                      disabled={isSubmitting}
                    >
                      {fields?.data?.data?.submitButtonText?.value}
                    </button>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default JobInquiryForm;
