import { tv } from 'tailwind-variants';

export const scheduleAppointmentStep2TailwindVariant = tv(
  {
    slots: {
      base: ['storeLocatorGlobalPopup'],
      step1HeadingWrapper: ['flex flex-row justify-between'],
      step1HeadingTitle: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold text-color-text-black',
      ],
      step1HeadingLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
        'cursor-pointer',
      ],
      loaderWrapper: [
        'w-full flex items-center gap-desk-space-between-base-vertical text-heading-desk-large-bold font-heading-desk-large-bold',
      ],
      editService: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold text-color-text-black cursor-pointer',
      ],
      img: ['h-[48px]'],
      checkbox: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
        'checked:bg-color-brand-primary-1-base',
        'rounded-[4px]',
        'border',
        'border-color-border-brand',
      ],
      checkboxIcon: [
        '[&>svg>*>*]:fill-color-background-white absolute [&>svg]:w-[24px] [&>svg]:h-[24px] invisible peer-checked:visible left-0',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
  },
  { responsiveVariants: ['lg'] }
);
