import { Auth, Configuration, DecodedToken, Tokens, UserInfo } from 'ordercloud-javascript-sdk';
import toExecuteBeforeSoftLogin from './handleSoftLogin';
import { MeUserWithXp } from 'src/redux/xp';
import { OcCurrentOrderState } from 'src/redux/ocCurrentOrder';

export async function tryRefreshExpiredTokens(
  ocUser: MeUserWithXp | undefined,
  ocCurrentOrder: OcCurrentOrderState | undefined
) {
  const clientID = Configuration.Get().clientID ?? '';
  try {
    // todo: Per our previous discussion the accessToken logic will be disabled and we will use only the identityToken refresh
    const accessToken = Tokens.GetAccessToken();
    if (accessToken && isTokenExpired(accessToken)) {
      await tryRefreshAccessToken(clientID, ocUser, ocCurrentOrder);
    }
    const identityToken = Tokens.GetIdentityToken();
    if (isTokenExpired(identityToken)) {
      await tryRefreshIdentityToken();
    }
  } catch (e) {
    console.error('request decorator error', e);
  }
}

export async function requestDecorator(
  ocUser: MeUserWithXp | undefined,
  ocCurrentOrder: OcCurrentOrderState | undefined
) {
  return await tryRefreshExpiredTokens(ocUser, ocCurrentOrder);
}

async function tryRefreshAccessToken(
  clientID: string,
  ocUser: MeUserWithXp | undefined,
  ocCurrentOrder: OcCurrentOrderState | undefined
): Promise<void> {
  try {
    const refreshToken = Tokens.GetRefreshToken();
    if (refreshToken) {
      const refreshRequest = await Auth.RefreshToken(refreshToken, clientID);
      const accessToken = refreshRequest.access_token;
      Tokens.SetAccessToken(accessToken);
    } else {
      const anonUserRequest = await Auth.Anonymous(clientID);
      Tokens.SetAccessToken(anonUserRequest.access_token);

      // trigger Soft login here: As we don't have refresh token, we need to trigger SoftLogin
      if (process.env.NEXT_PUBLIC_ENABLE_SOFTLOGIN == 'true') {
        await toExecuteBeforeSoftLogin(ocUser, ocCurrentOrder);
      }
    }
  } catch (_e) {
    throw new Error("Error, can't retrieve new token");
  }
}

async function tryRefreshIdentityToken(): Promise<void> {
  const userInfo = await UserInfo.GetToken();
  Tokens.SetIdentityToken(userInfo.identity_token);
}

export function isTokenExpired(token?: string): boolean {
  if (!token) {
    return true;
  }
  const decodedToken = parseJwt(token);
  const currentSeconds = Date.now() / 1000;
  const currentSecondsWithBuffer = currentSeconds - 10;
  return decodedToken.exp < currentSecondsWithBuffer;
}

function parseJwt(token: string): DecodedToken {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      decodeBase64(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (_e) {
    throw new Error('Invalid token');
  }
}

function decodeBase64(str: string) {
  return atob(str);
}
