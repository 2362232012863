/* eslint-disable */
import ComponentContext from 'lib/context/ComponentContext';
import { useTheme } from 'lib/context/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useOcSelector } from 'src/redux/ocStore';
import apiConfig from 'src/utils/apiConfig';
import { apiRequest } from 'src/utils/apiWrapper';
export type tokenAPIResponse = {
  Token?: string;
  Url?: string;
} | null;
export async function getBVData(data: any, themeName: string, token: string) {
  try {
    return apiRequest(`${apiConfig?.bazarVoiceReviewAPI?.bazarVoiceReviewAPIEndpoint}`, {
      method: 'POST',
      data: data,
      headers: { site: themeName, Authorization: token },
    })
      .then((response: tokenAPIResponse) => {
        return response;
      })
      .catch((err) => {
        console.error('err', err);
        return null;
      });
  } catch (err) {
    console.error('In getBVData err', err);
    return null;
  }
}
const BazarVoiceToken = () => {
  const [bvResponse, setBVResponse] = useState<tokenAPIResponse>({});
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);
  const userId = useOcSelector((s) => s?.ocUser?.user?.ID);
  const email = useOcSelector((s) => s?.ocUser?.user?.Email);
  const { themeName } = useTheme();
  const token = useAuthToken();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  async function getToken() {
    if (userId && email) {
      getBVData({}, themeName, token)
        .then((response: tokenAPIResponse) => {
          const meta = document.createElement('meta');
          meta.name = 'bv:userToken';
          if (response?.Token) {
            meta.content = response?.Token;
            document.head.appendChild(meta);
          }
          setBVResponse(response);
          return () => {
            // Cleanup if necessary, like removing the meta tag when the component unmounts
            document.head.removeChild(meta);
          };
        })
        .catch((err) => {
          console.error('error:', err);
          setBVResponse(err);
        });
    }
  }
  useEffect(() => {
    if (!isAnonymous) {
      getToken();
    }
  }, [isAnonymous, userId, email]);
  useEffect(() => {
    if (bvResponse?.Token) {
      setcomponentContextData({ ...componentContextData, bvToken: bvResponse?.Token });
    }
  }, [bvResponse?.Token]);
  return <></>;
};
export default BazarVoiceToken;
