import { NextRequest, NextResponse } from 'next/server';
import { MiddlewarePlugin } from '..';

const PARENT_PRODUCT_ID_PREFIX = '_parent_id_';
const PRODUCT_SKU_PREFIX = '_product_sku_';

export interface ProductUrlData {
  parentid: string;
  productSku: string;
}

export function getProductDataFromRewrite(pathname: string): ProductUrlData | null {
  const path = pathname.endsWith('/') ? pathname : pathname + '/';
  const result = path.match(`${PARENT_PRODUCT_ID_PREFIX}(.*?)\\/${PRODUCT_SKU_PREFIX}(.*?)\\/`);

  if (result && result[1] !== '') {
    const [, parentid, productSku] = result;
    return {
      parentid,
      productSku,
    };
  }
  // Cannot serialize undefined, so make sure it's null instead of undefined.
  return null;
}

/**
 * Normalize a product rewrite path (remove product data)
 * @param {string} pathname the pathname
 * @returns {string} the pathname with product data removed
 */
export function normalizeProductIdSkuRewrite(pathname: string): string {
  let newPathName = pathname;
  // Remove product id
  const idResult = pathname.match(`${PARENT_PRODUCT_ID_PREFIX}.*?(?:\\/|$)`);
  if (idResult) {
    newPathName = newPathName.replace(idResult[0], '');
  }
  // Remove producy sku
  const skuResult = pathname.match(`${PRODUCT_SKU_PREFIX}.*?(?:\\/|$)`);
  if (skuResult) {
    newPathName = newPathName.replace(skuResult[0], '');
  }
  return newPathName;
}

class PdpSkuPlugin implements MiddlewarePlugin {
  order = 0;
  checkIsHavingFourCategories(pathnameWithQueryString: string) {
    const regex =
      /^\/categories\/(?<level1>[\w\-]+)\/(?<level2>[\w\-]+)\/(?<level3>[\w\-]+)\/(?<level4>[\w\-]+)\/(?<prodName>[\w\-]+)\/(?<parentId>[0-9]+)(\?sku=(?<prodSku>[0-9]+))?/;
    const result = regex.exec(pathnameWithQueryString);
    if (result?.groups) {
      return `/categories/${result?.groups?.level1}/${result?.groups?.level2}/${result?.groups?.level4}/${result?.groups?.prodName}/${result?.groups?.parentId}`;
    }
    return null;
  }
  getPdpSkuRewritePath(pathnameWithQueryString: string) {
    const regex =
      /^\/categories\/(?<level1>[\w\-]+)\/(?<level2>[\w\-]+)\/(?<level3>[\w\-]+)\/(?<prodName>[\w\-]+)\/(?<parentId>[0-9]+)(\?sku=(?<prodSku>[0-9]+))?/;

    const result = regex.exec(pathnameWithQueryString);

    if (result?.groups) {
      const { parentId, prodSku } = result.groups;
      return `/${PARENT_PRODUCT_ID_PREFIX}${parentId ?? ''}/${PRODUCT_SKU_PREFIX}${
        prodSku ?? ''
      }/pdp`;
    }

    return null;
  }
  /**
   * exec async method - to find coincidence in url.pathname and redirects of site
   * @param req<NextRequest>
   * @returns Promise<NextResponse>
   */
  async exec(req: NextRequest, res?: NextResponse): Promise<NextResponse> {
    // Response will be provided if other middleware is run before us
    let response = res || NextResponse.next();

    const pathname = req.nextUrl.pathname;

    const params = new URLSearchParams(req.nextUrl.search);

    // When fetching data async the path may end up in the querystring for the middleware, which we don't want
    params.delete('path');
    // Make sure to also manually pass query string because it's not part of pathname.
    const fourCategoryPath = this.checkIsHavingFourCategories(pathname + '?' + params.toString());

    // No need to check rewrites if we got 4 categories
    const rewritePath = !!fourCategoryPath
      ? null
      : // Make sure to also manually pass query string because it's not part of pathname.
        this.getPdpSkuRewritePath(pathname + '?' + params.toString());

    // If no path found then no need to do anything further
    if (!rewritePath && !fourCategoryPath) {
      return response;
    }

    // Path can be rewritten by previously executed middleware
    const basePath = res?.headers.get('x-sc-rewrite') || pathname;

    // Get any existing rewrite so we can include it in the final rewrite.
    const existingRewrite =
      pathname === '/'
        ? basePath.substring(0, basePath.length - 1)
        : basePath.replace(pathname, '');

    const rewriteUrl = req.nextUrl.clone();
    // If four categories path found then return a redirect response else rewrite
    if (!!fourCategoryPath) {
      rewriteUrl.pathname = fourCategoryPath;
      response = NextResponse.redirect(rewriteUrl);
    } else {
      // Add the existing rewrite and our new rewrite
      rewriteUrl.pathname = existingRewrite + rewritePath;
      response = NextResponse.rewrite(rewriteUrl, res);
    }

    return response;
  }
}

export const pdpSkuPlugin = new PdpSkuPlugin();
