import { tv } from 'tailwind-variants';

export const scheduleAppointmentTailwindVariant = tv(
  {
    slots: {
      scheduleAppointmentWrapper: ['relative'],
      scheduleAppointmentTitle: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold text-color-text-black mb-[20px]',
      ],
      scheduleAppointementSteps: ['flex flex-col'],
      steps: [
        'py-desk-global-spacing-spacing-6 px-desk-padding-micro-x  border border-color-border-mid border-b-0 border-l-0 border-r-0 last:border-b',
      ],
    },
  },
  { responsiveVariants: ['lg'] }
);
